.event
    position relative
    display inline-block
    grid-column span 2
    @media $tLandscapeMax
        grid-column auto
    @media $mPortraitMax
        display flex
        flex-direction column
        align-items flex-start

    &:hover

        .event__photo

            img
                @media $desktop
                    opacity .1

    &__cover_link
        position absolute
        z-index 1
        top 0
        right 0
        bottom 0
        left 0

    &__photo
        position relative
        background-color #661339

        &::after
            content ""
            position absolute
            top 0
            right 0
            bottom 0
            left 0
            background-image linear-gradient(0deg, rgba(#080808, .84), transparent)

        img
            width 100%
            transition opacity .3s ease

        .icon
            display none
            position absolute
            z-index 1
            top 0
            right 0
            bottom 0
            left 0
            margin auto
            width 78px
            height 13px
            color #ffffff
            opacity 0
            transition opacity .3s ease
            @media $tLandscapeMax
                display inline-block

        &:hover

            img
                @media $tLandscapeMax
                    opacity .1

            .icon
                opacity 1

    &__content
        position absolute
        bottom 0
        left 0
        right 0
        padding 2.34vw 2.6vw
        @media $quadHD
            padding 45px 50px
        @media $laptopMax
            padding 25px
        @media $tLandscapeMax
            position static
            padding 15px 15px 0

    &__meta
        margin-bottom 25px
        font-size 1.6rem
        line-height 1
        color rgba(#ffffff, .7)
        @media $laptopMax
            margin-bottom 20px
        @media $tLandscapeMax
            margin-bottom 20px
            color rgba(#333333, .7)

    &__date
        margin-right 18px

    &__tag
        position relative
        z-index 1
        transition color .3s ease

        &:hover
            color #ffffff
            @media $tLandscapeMax
                color rgba(#b7b7b7, .7)

    &__title
        line-height 1

    &__link
        font-size 3rem
        line-height 3.2rem
        color #ffffff
        pointer-events none
        transition opacity .3s ease
        @media $laptopMax
            font-size 2.6rem
            line-height 2.9rem
        @media $tLandscapeMax
            font-size 2.5rem
            line-height 2.8rem
            color #333333
            pointer-events auto
        @media $mLandscapeMax
            font-size 2.3rem
            line-height 2.6rem
        @media $mPortraitMax
            font-size 2.1rem
            line-height 2.4rem

        &:hover
            opacity .3
