.modal
    display none
    position fixed
    z-index 999
    top 0
    right 0
    bottom 0
    left 0
    min-height 100vh
    background-color #0e0e18
    overflow-x hidden
    overflow-y hidden

    .btn--close
        position absolute
        z-index 1
        top 45px
        right 60px
        color #ffffff
        opacity 1
        transition opacity .3s ease
        @media $laptopMax
            right 45px
        @media $mLandscapeMax
            top 15px
            right 15px

        &:hover
            opacity .7

        &:active
            opacity .4

    //.form_wrapper
    //.success
    //    padding-right 11.45vw
    //    @media $quadHD
    //        padding-right 220px
    //    @media $tPortraitMax
    //        padding-right 0

    .form
        max-width 500px

    &__inner
        @extend .container
        position relative
        display flex
        align-items center
        padding-top 20px
        padding-bottom 20px
        min-height 100%
        @media $tPortraitMax
            justify-content center

    &__content
        max-width 55.2vw
        margin-left auto
        padding-left 11.45vw
        //padding-right 11.45vw
        padding-right 9.89vw
        @media $quadHD
            padding-left 220px
            padding-right 220px
        @media $desktopMax
            padding-left 70px
            padding-right 70px
        @media $tLandscapeMax
            max-width 61vw
        @media $tPortraitMax
            max-width none
            padding-left 0
            padding-right 0
            margin-left 0

    &__fox
        position fixed
        top 50%
        left 7.13vw
        transform translateY(-50%)
        width 27.97vw
        height 28.44vw
        color #010103
        @media $quadHD
            width 537px
            height 546px
        @media $tPortraitMax
            left 50%
            transform translate(-50%, -50%)
            width 80vw
            height 81.3vw
            opacity .7
