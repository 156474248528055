.video
    position relative

    &:hover

        .video__cover
            img
                opacity .15

        .video__play
            color #e41563

    &__cover
        background-color #1d232c

        img
            width 100%
            margin 0
            opacity .35
            transition opacity .3s ease


    &__play
        position absolute
        z-index 1
        top 0
        left 0
        width 100%
        height 100%
        display flex
        align-items center
        justify-content center
        color #ffffff
        transition color .3s ease

        .icon
            width 98px
            height 98px
            @media $tPortraitMax
                width 70px
                height 70px
            @media $mPortraitMax
                width 50px
                height 50px

    &__title
        position absolute
        top 69.17%
        left 0
        right 0
        font-size 2.4rem
        line-height 1
        color #ffffff
        text-align center
        @media $tPortraitMax
            top calc(46% + 70px)
            font-size 2rem
        @media $mPortraitMax
            top calc(42% + 50px)
