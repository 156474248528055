.specs
    min-width 100%
    font-size 1.6rem
    line-height 1.2
    color #636363

    tr
        border-bottom 1px solid #f1f1f1

        &:last-of-type
            border-bottom none

    td
        width 50%
        padding 9px 18px
        @media $mLandscapeMax
            padding 9px 10px

    &__name
        border-right 1px solid #000000

    &__link
        transition color .3s ease

        &:hover
            color #e41563
