.btn
    position relative
    display inline-flex
    justify-content center
    align-items center
    padding 0
    border none
    box-shadow none
    outline none
    background-color transparent
    user-select none

    &--default
        padding 14px 27px 12px
        font-size 2.2rem
        line-height 1
        color #ffffff
        border-radius 25px
        border 1px solid rgba(#ffffff, .2)
        transition border-color .3s ease
        @media $mLandscapeMax
            padding-left 20px
            padding-right 20px
            font-size 2rem

        &:hover
            border-color #ffffff

    &--pink
        padding 15px 25px 15px
        font-size 2rem
        line-height 1
        color #ffffff
        border-radius 25px
        background-color #e41563
        transition background-color .3s ease

        &:hover
        &:active
            background-color #a0194c

    &--close
        color #000000
        opacity .2
        transition opacity .3s ease

        &:hover
            opacity 1

        .icon
            width 25px
            height 25px

    &--burger
        width 40px
        height 40px

        .icon
            width 26px
            height 6px
            color #425171

    &--disabled
        opacity .3
        pointer-events none
