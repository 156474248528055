.cart
    position relative
    display flex

    &:hover
        .cart__icon
            opacity .7

    &:active
        .cart__icon
            opacity .3

    &__icon
        color #333333
        transition opacity .3s ease

    &__plus
        width 7px
        height 7px
        transform translateY(-1px) rotateZ(45deg)

    &__badge
        position absolute
        top 15px
        left 10px
        display inline-block
        min-width 21px
        height 21px
        padding-top 5px
        padding-left 3px
        padding-right 3px
        font-family $thirdFontFamily
        font-size 1.2rem
        line-height 1
        font-weight 400
        color #ffffff
        text-align center
        border-radius 10.5px
        background-color #e41563

        &--cart_counter

            .cart__badge_count
                display inline-block

            .cart__plus
                display none

    &__badge_count
        display none
