.video_modal
    display none
    position fixed
    z-index 999
    top 0
    right 0
    bottom 0
    left 0
    padding 20px
    overflow-y hidden

    &__overlay
        position fixed
        top 0
        right 0
        bottom 0
        left 0
        background-color #0e0e18
        opacity .9

    &__wrapper
        position absolute
        top 0
        right 0
        bottom 0
        left 0
        margin auto
        max-width 800px
        max-height 450px
        height 56.25vw

    &__video
        position relative
        height 0
        padding-bottom 56.25%

        iframe
            position absolute
            top 0
            left 0
            width 100%
            height 100%
            border 0

    &__close
        position absolute
        z-index 1
        top 2.08vw
        right 2.08vw
        color #ffffff
        opacity 1
        transition opacity .3s ease
        @media $quadHD
            top 40px
            right 40px
        @media $tPortraitMax
            top 15px
            right 15px

        &:hover
            opacity .7

        &:active
            opacity .4
