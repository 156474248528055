.catalogs
    display grid
    grid-template-columns repeat(3, 27.06%)
    grid-gap 50px 30px
    justify-content space-between
    @media $desktopMax
        grid-template-columns repeat(3, 30%)
    @media $tLandscapeMax
        grid-template-columns repeat(3, 1fr)
        grid-gap 45px 3.2vw
    @media $mLandscapeMax
        grid-template-columns repeat(2, 1fr)
        grid-column-gap 30px
    @media $mPortraitMax
        grid-template-columns 1fr
        grid-gap 40px 0
