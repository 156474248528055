.about
    position relative
    left 50%
    transform translateX(-50%)
    width 100vw
    background-color #f7f8fb
    overflow hidden

    .btn
        //margin-right 1.56vw
        padding-top 15px
        padding-bottom 13px
        font-size 2rem
        color #333333
        border-color #cbcbcb
        transition border-color .3s ease
        @media $mLandscapeMax
            margin-bottom 15px

        &:last-child
            @media $mLandscapeMax
                margin-bottom 0

        //&:last-child
        //    margin-right 0

        &:hover
            border-color #5f5f5f

    &__inner
        @extend .container
        position relative
        padding-top 6.25vw
        padding-bottom 5.2vw
        @media $quadHD
            padding-top 120px
            padding-bottom 100px
        @media $tPortraitMax
            padding-top 65px
            padding-bottom 50px
        @media $mPortraitMax
            padding-top 50px

    &__fox
        position absolute
        top 11.82vw
        left 50%
        transform translateX(-50%)
        display flex
        opacity .25
        transition top 2.3s ease, opacity 2s ease
        @media $quadHD
            top 227px
        @media $tPortraitMax
            top 20px
        @media $mPortraitMax
            top 320px

        .icon
            width 33.64vw
            height 34.16vw
            color #ececec
            @media $quadHD
                width 646px
                height 656px
            @media $tPortraitMax
                width 60vw
                height 60.93vw
            @media $mPortraitMax
                width 80vw
                height 81.24vw

        &--appear
            @media $tLandscape
                top 0
                opacity .65

    &__top
        position relative
        z-index 1
        max-width 690px
        margin-left auto
        margin-right auto

    &__feature
        text-align center

        &_title
            margin-bottom 18px
            font-size 4rem
            line-height 1.1
            color #333333
            @media $mLandscapeMax
                font-size 3.2rem
            @media $mPortraitMax
                margin-bottom 30px
                font-size 2.8rem

        &_text
            font-size 2rem
            line-height 1.3

    &__actions
        display flex
        justify-content space-between
        align-items center
        margin-top 3.38vw
        @media $quadHD
            margin-top 65px
        @media $tPortraitMax
            margin-top 35px
        @media $mLandscapeMax
            flex-direction column

    &__more.btn
        @media $mPortraitMax
            padding-left 10px
            padding-right 10px
            font-size 1.8rem

    &__social
        position relative
        z-index 1
        margin-top 8.85vw
        @media $quadHD
            margin-top 170px
        @media $tPortraitMax
            margin-top 90px
        @media $mLandscapeMax
            margin-top 55px
