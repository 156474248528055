.product
    display flex
    padding-bottom 5.2vw
    border-bottom 1px solid #f1f1f1
    @media $quadHD
        padding-top 100px
    @media $tLandscapeMax
        flex-wrap wrap
    @media $mLandscapeMax
        padding-bottom 40px
    @media $mPortraitMax
        flex-direction column

    &__photo
        flex 0 1 353px
        display flex
        justify-content center
        align-items center
        margin-right 52px
        user-select none
        @media $laptopMax
            flex-basis 280px
        @media $tLandscapeMax
            flex-basis calc(25% - 25px)
            margin-right 50px
        @media $mLandscapeMax
            align-items flex-start
        @media $mPortraitMax
            flex auto
            margin-right 0
            margin-bottom 30px

        img
            max-height 545px
            @media $mPortraitMax
                max-width 100px

    &__photo_link
        display flex
        align-items center
        min-height 545px
        @media $mPortraitMax
            min-height auto

    &__info
        flex 0 1 456px
        display flex
        flex-direction column
        margin-right 52px
        @media $tLandscapeMax
            flex-basis calc(75% - 25px)
            margin-right 0
        @media $mPortraitMax
            flex auto

    &__description
        flex 0 1 693px
        @media $tLandscapeMax
            flex-grow 1
            margin-top 50px
        @media $mLandscapeMax
            margin-top 40px
        @media $mPortraitMax
            flex auto

        &_title
            margin-bottom 35px
            font-size 3rem
            line-height 1
            color #1c1c1c
            opacity .9

    &__title
        margin-bottom 30px
        font-size 4rem
        line-height 4.5rem
        color #1c1c1c
        opacity .9
        @media $desktopMax
            font-size 3.7rem
            line-height 4.2rem
        @media $laptopMax
            font-size 3.2rem
            line-height 3.7rem
        @media $tPortraitMax
            font-size 3rem
            line-height 3.5rem
        @media $mLandscapeMax
            font-size 2.8rem
            line-height 3.2rem
        @media $mPortraitMax
            font-size 2.4rem
            line-height 2.7rem
            display none

        &--mobile
            display none
            @media $mPortraitMax
                display block

    &__link
        color inherit
        transition color .3s ease

        &:hover
            color #e41563

    &__specials
        display flex
        align-items center
        margin-bottom 25px

    &__special
        display inline-block
        margin-right 5px
        margin-bottom 5px
        padding 6px 20px
        font-family $thirdFontFamily
        font-size 1.1rem
        line-height 1
        color #ffffff
        border-radius 12px
        background-color #e41563
        transition background-color .3s ease

        &:last-of-type
            margin-right 0

        &:hover
        &:active
            background-color #a0194c

    &__footer
        display flex
        align-items center
        margin-top 50px
        @media $mLandscapeMax
            flex-wrap wrap
            justify-content space-between
            margin-top 25px

    &__prices
        display flex
        margin-top 5px
        margin-right 2.29vw
        @media $quadHD
            margin-right 44px
        @media $mLandscapeMax
            margin 10px 25px 5px 0

    &__price
        font-size 2.8rem
        line-height 1
        color #333333

    &__currency
        font-family $thirdFontFamily
        line-height 1
        font-weight 300

    &__detailed
        margin-left 2.5vw
        @media $quadHD
            margin-left 48px
        @media $mLandscapeMax
            margin-top 15px
            margin-left 0
            flex 1 0 100%
            text-align right

    &__detailed_link
        font-size 2rem
        line-height 1
        color #333333
        transition opacity .3s ease

        &:hover
            opacity .7

        &:active
            opacity .3

    &__actions
        flex-grow 1
        display flex
        justify-content space-between
        align-items center
        @media $mLandscapeMax
            padding-right 15px

    &__adding
        @media $mPortraitMax
            margin-left auto

    &--modal
        justify-content space-between
        padding-top 0
        padding-bottom 0
        border-bottom none
        @media $mLandscapeMax
            padding-bottom 0

        .specs
            margin-bottom 50px
            @media $mLandscapeMax
                margin-bottom 25px
            @media $mPortraitMax
                margin-left -10px
                margin-right -10px

        .product__photo
            margin-right 30px

        .product__info
            margin-right 0

        .product__footer
            margin-top auto

        .cart_adding__add
            padding-left 34px
            padding-right 34px
            @media $mLandscapeMax
                padding-left 20px
                padding-right 20px
