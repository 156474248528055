.slider
    position relative

    &__track
        overflow hidden

    &__slides
        overflow hidden
        transition transform 1.3s ease

        &::before
        &::after
            content ""
            display table
            clear both

    &__slide
        position relative
        float left
        width 100%
        max-height 100vh
        overflow hidden

        &--active

            .slider__title
                opacity 1
                transform translateX(0)

            .slider__text
                opacity .7
                transform scale(1)

            .slider__btn
                opacity 1
                transform translateY(0)

    &__cover
        display block
        background-color #1d232c

        img
            width 100%
            opacity .3

        &[href]
            cursor zoom-in

    &__content
        position absolute
        top var(--header_height)
        right 0
        bottom 0
        left 0
        display flex
        flex-direction column
        justify-content center
        align-items flex-start
        max-width 1600px
        margin-left auto
        margin-right auto
        //max-width 1066px
        //padding 30px 100px 30px 200px
        color #ffffff
        @media $tPortraitMax
            bottom 45px

        &_inner
            max-width 1060px
            padding 1.56vw 5.2vw 1.56vw 10.4vw
            @media $quadHD
                padding 30px 100px 30px 200px
            @media $tLandscapeMax
                padding-right 10.4vw
            @media $tPortraitMax
                padding 30px 25px

    &__title
        margin-bottom 2.6vw
        font-size 7rem
        line-height 1
        transform translateX(-100%)
        opacity 0
        transition transform .5s ease, opacity .4s ease
        @media $quadHD
            margin-bottom 50px
        @media $desktopMax
            font-size 6rem
        @media $laptopMax
            font-size 5rem
        @media $tLandscapeMax
            font-size 4rem
        @media $tPortraitMax
            margin-bottom 25px
            font-size 3.6rem
        @media $mLandscapeMax
            font-size 3.2rem

    &__link
        transition opacity .3s ease

        &:hover
            opacity .6

    &__text
        font-size 2.4rem
        line-height 2.8rem
        opacity 0
        transform scale(1.1)
        transition transform .5s ease, opacity .4s ease
        @media $laptopMax
            font-size 2.2rem
            line-height 2.7rem
        @media $tPortraitMax
            font-size 2.1rem
            line-height 2.6rem
        @media $mLandscapeMax
            font-size 2rem

    &__btn
        margin-top 3.38vw
        transform translateY(25px)
        opacity 0
        transition transform .5s ease, opacity .4s ease, border-color .3s ease
        @media $quadHD
            margin-top 65px
        @media $tPortraitMax
            margin-top 35px

    .slider__navigation
        @media $tPortraitMax
            position absolute
            left 50%
            bottom 10px
            transform translateX(-50%)
            display flex
            justify-content space-between
            width 200px
            height 40px
        @media $mLandscapeMax
            left 25px
            transform translateX(0)
            width 100px

    &__prev
    &__next
        position absolute
        //top 36.52%
        top 0
        bottom 0
        justify-content center
        align-items center
        //width 10vw
        width 10.31%
        //min-width 160px
        min-width 200px
        font-size 0
        opacity 0
        transition opacity .3s ease
        @media $tPortraitMax
            position relative
            top auto
            left auto
            right auto
            opacity 1
            width auto
            min-width auto
            padding-bottom 0

        &:hover
            opacity 1

    &__prev
        left 0
        //justify-content flex-start

        .slider__arrow_long
        .slider__arrow_short
            transform rotateZ(180deg)
            //margin-left 60px

    &__next
        right 0
        //justify-content flex-end

        //.icon
        //    margin-right 60px

    &__arrow_long
        width 78px
        height 13px
        color #ffffff
        @media $mLandscapeMax
            display none

    &__arrow_short
        display none
        width 28px
        height 11px
        color #ffffff
        @media $mLandscapeMax
            display block

    &__pagination
        position absolute
        right 60px
        bottom 40px
        @media $tPortraitMax
            right 40px
            bottom 30px
        @media $mLandscapeMax
            right 25px
            bottom 25px

    &__dots
        display flex
        align-items center

    &__dot
        width 4px
        height 4px
        margin-right 11px
        border-radius 2px
        background-color #ffffff
        opacity .2
        cursor pointer
        transition opacity .3s ease, width .3s ease
        @media $quadHD
            width 6px
            height 6px
            border-radius 3px

        &:last-of-type
            margin-right 0

        &:hover
            opacity 1

        &--current
            width 26px
            opacity 1
            pointer-events none

    &--main
        @media $tPortraitMax
            margin-top var(--header_height)

        .slider__content
            @media $tPortraitMax
                top 0

        .slider__prev
        .slider__next
            top var(--header_height)
            padding-bottom 3.85%
            min-width auto
            @media $tPortraitMax
                top 0
                padding-bottom 0

    &--empty
        .slider__navigation
            display none
