.page_hero
    position relative
    width 100%
    max-height 100vh
    background-color #1d232c
    @media $tPortraitMax
        padding-top var(--header_height)

    &__photo

        img
            display block
            object-fit cover
            width 100%
            opacity .3
            max-height 100vh
            min-height 65vh

    &__video
        display block
        object-fit cover
        width 100%
        opacity .3
        max-height 100vh
        min-height 65vh

    &__content
        @extend .container
        position absolute
        top var(--header_height)
        bottom 0
        left 0
        right 0
        //transform translateX(-50%)
        display flex
        justify-content space-between
        align-items center
        margin-left auto
        margin-right auto

    &__title
        width 100%
        padding-left 12.81%
        padding-right 12.81%
        font-size 7rem
        line-height 1.2
        color #ffffff
        @media $desktopMax
            font-size 6.5rem
        @media $laptopMax
            padding-left 0
            padding-right 0
            font-size 5.5rem
        @media $tLandscapeMax
            font-size 5rem
        @media $tPortraitMax
            font-size 4.5rem
        @media $mLandscapeMax
            font-size 3.6rem
