.catalog_card
    display flex
    flex-direction column

    &:hover

        .catalog_card__photo
            img
                width 70%
                @media $tLandscapeMax
                    width 85%

        .catalog_card__text
            transform translateX(-20px)
            opacity 1
            pointer-events auto
            transition-duration .45s, .3s

        .catalog_card__title

            .icon
                transform translateX(20px)
                opacity 1

    &__photo
        height 0
        padding-bottom 100%

        img
            width 100%
            transition width .3s ease-in-out

    &__text
        margin-top -4.7rem
        font-family $additionalFontFamily
        font-size 7.1rem
        line-height 1
        color #333333
        transform translateX(-100px)
        opacity 0
        pointer-events none
        transition transform .45s ease, opacity .1s ease
        @media $desktopMax
            font-size 6rem
            transform translateX(-70px)
        @media $laptopMax
            font-size 5rem
        @media $tLandscapeMax
            display none

    &__title
        margin-top 15px
        font-size 5rem
        line-height 1
        color #000000
        @media $desktopMax
            font-size 4rem
        @media $laptopMax
            font-size 3.7rem
        @media $tLandscapeMax
            margin-top 25px
            font-size 3.2rem
        @media $tPortraitMax
            font-size 2.9rem
        @media $mPortraitMax
            margin-top 20px
            font-size 3.6rem

        .icon
            opacity 0
            transition transform .3s ease, opacity .3s ease
            @media $tLandscapeMax
                display none
