.pagination

    &__list
        display flex
        align-items center

    &__link
        display flex
        align-items center
        justify-content center
        width 50px
        height 50px
        font-family $thirdFontFamily
        font-size 1.8rem
        line-height 1
        color #333333
        border-radius 50%
        user-select none
        transition color .3s ease
        @media $mPortraitMax
            width 40px
            height 40px
            font-size 1.6rem

        &[href]:hover
            color #e41563

        &--current
            color #ffffff
            background-color #e41563
            cursor default

        &--prev
        &--next
            color #787878
            opacity .3

            &[href]
                opacity 1

        &--next
            margin-left 2px

        &--prev
            margin-right 2px

            .icon
                transform rotate(180deg)

    &__arrow
        width 28px
        height 11px
