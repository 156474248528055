.service_card
    @media $tPortraitMax
        display flex
        flex-direction column

    &:hover
        .service_card__arrow
            transform translateX(0)
            opacity 1

    &__title
        margin-bottom 25px
        font-size 4rem
        line-height 1.2
        color #333333
        @media $laptopMax
            font-size 3.6rem
        @media $tLandscapeMax
            font-size 3.3rem

    &__description
        font-size 2rem
        color #959595
        @media $tPortraitMax
            margin-bottom 35px

    &__arrow
        position absolute
        bottom 40.2%
        right -26px
        transform translateX(-100%)
        width 78px
        height 13px
        opacity 0
        transition transform .35s ease, opacity .25s ease
        @media $tLandscapeMax
            right 0
        @media $tPortraitMax
            display none
