.publication_card
    grid-area publication_card
    padding 2.6vw 1.92vw 105px
    @media $quadHD
        padding 50px 37px 105px
    @media $tPortraitMax
        padding 25px 25px 0

    .card__photo
        left 1.92vw
        transform translateY(-105px)
        height 70px
        margin-top 35px
        @media $quadHD
            left 37px
        @media $tPortraitMax
            height auto
            transform none
            margin-top 30px
            text-align left

    &:hover

        .publication_card__arrow
            transform translateX(20px)

        .card__photo
            height 100px
            margin-top 0
            @media $tPortraitMax
                height auto
                margin-top 30px

    &__top
        @media $tPortraitMax
            display flex
            justify-content space-between
        @media $mLandscapeMax
            flex-direction column

    &__title
        width 75%
        font-size 3rem
        line-height 3.8rem
        color #333333
        @media $tPortraitMax
            margin-right 15px
            font-size 2.7rem
            line-height 3.4rem
        @media $mLandscapeMax
            width 100%
            margin-right 0
            margin-bottom 15px
        @media $mPortraitMax
            font-size 2.4rem
            line-height 3rem

    &__continue
        position absolute
        top 28px
        right -40px
        font-size 4rem
        line-height 1
        color #333333
        @media $tPortraitMax
            position static
            font-size 3.5rem

    &__arrow
        width 78px
        height 13px
        margin-left 30px
        color #787878
        transition transform .3s ease

    &--simple
        padding 2.6vw 1.92vw 135px
        @media $quadHD
            padding 50px 37px 135px
        @media $tPortraitMax
            padding 25px 25px 0

        .card__photo
            transform translateY(-135px)
            margin-top 65px
            @media $laptopMax
                right 1.92vw
            @media $tLandscapeMax
                right auto
            @media $tPortraitMax
                height auto
                transform none
                margin-top 30px

        &:hover

            .card__photo
                margin-top 35px
                @media $tPortraitMax
                    height auto
                    margin-top 30px


        .publication_card__title
            width auto
            @media $desktopMax
                font-size 2.8rem
                line-height 3.6rem
            @media $laptopMax
                font-size 2.6rem
                line-height 3.3rem
            @media $mPortraitMax
                font-size 2.4rem
                line-height 3rem

        .publication_card__continue
            top auto
            bottom 28px
            right 23px
            @media $desktopMax
                bottom 110px
            @media $tPortraitMax
                position static

            .icon
                margin-left 0
