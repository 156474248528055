.main_nav

    .submenu
        position absolute
        z-index 10
        top 100%
        left 0
        right 0
        visibility hidden
        width 1630px
        max-width 100%
        margin 0 auto
        padding-left 15px
        padding-right 15px
        opacity 0
        transition opacity .3s ease, visibility .3s ease
        @media $desktopMax
            width 1370px
        @media $laptopMax
            width 1200px
        @media $tPortraitMax
            display none

        &::before
            @media $tLandscape
                content ""
                position absolute
                z-index -1
                top 0
                right -100%
                bottom 0
                left -100%
                background-color #191919

    &__list
        display flex
        @media $tPortraitMax
            flex-direction column

    &__item
        margin-right 2.6vw
        line-height 1
        border-bottom 1px solid transparent
        transition border-color .3s ease
        @media $quadHD
            margin-right 50px
        @media $laptopMax
            margin-right 25px
        @media $tLandscapeMax
            margin-right 20px
        @media $tPortraitMax
            margin-right 0
            border-bottom-color #4b5364

        &:last-of-type
            margin-right 0

        &:hover
            border-bottom-color initial

            .submenu
                @media $tLandscape
                    visibility visible
                    opacity 1

        &--current
            color #e41563

            &:hover
                border-bottom-color #333333

    &__link
        position relative
        display block
        padding-top 25px
        padding-bottom 24px
        font-size 2.2rem
        @media $laptopMax
            font-size 1.8rem
        @media $tPortraitMax
            padding 16px 11px

    &__count
        position absolute
        top 13px
        left calc(100% + 2px)
        display inline-block
        min-width 21px
        height 21px
        padding-top 5px
        padding-left 3px
        padding-right 3px
        font-family $thirdFontFamily
        font-size 1.2rem
        font-weight 400
        color #ffffff
        text-align center
        border-radius 50%
        background-color #000000
        pointer-events none
        @media $laptopMax
            left calc(100% - 2px)
        @media $tLandscapeMax
            top 10px
            left calc(100% - 5px)
        @media $tPortraitMax
            top 5px
            left auto
            margin-left 10px
            background-color #e41563
