.form

    &__inner
        display flex
        flex-wrap wrap
        margin 0 -5px

    &__group
        position relative
        margin-bottom 40px
        padding 0 5px
        @media $mLandscapeMax
            margin-bottom 30px

        &:last-child
            margin-bottom 0

        &--full
            width 100%

        &--not_empty
            .label
                top 0
                left 5px
                font-size 1.2rem
                opacity .5

        &--error

            .input
            .label
                color #e41563

            .input
                border-bottom-color #e41563

            .form__error_message
                display block

        &--disabled
            opacity .3s
            pointer-events none

    &__error_message
        display none
        padding 10px 10px 0
        font-size 1.8rem
        color #e41563

    &__buttons
        margin-top 40px

    &--sending
        position relative
        visibility hidden

        &::before
            content ""
            position absolute
            top 0
            right 0
            bottom 0
            left 0
            margin auto
            width 50px
            height 50px
            border-radius 50%
            background-color #e41563

        &::after
            content ""
            position absolute
            top 0
            right 0
            bottom 0
            left 0
            margin auto
            width 18px
            height 18px
            border-radius 50%
            border 2px solid #ffffff
            border-right-color transparent
            animation spin 1.5s linear infinite
