*
    box-sizing border-box

a
    color inherit
    text-decoration none

img
    max-width 100%

input, textarea, select
    outline none

label
    cursor text

::selection
    color #ffffff
    background-color #1c1c1c
