.instagram_tiles
    grid-area instagram_tiles

    &__heading
        margin-bottom 3.38vw
        text-align center
        @media $quadHD
            margin-bottom 65px
        @media $tPortraitMax
            margin-bottom 35px
        @media $mPortraitMax
            margin-bottom 25px

    &__link
        font-size 4rem
        line-height 1
        color #333333
        @media $mLandscapeMax
            font-size 2.6rem

        .icon
            margin-left 24px
            transition transform .3s ease

        &:hover
            .icon
                transform translateX(20px)

    &__list
        display flex
        @media $mLandscapeMax
            display grid
            grid-template-columns repeat(2, 1fr)
            grid-gap 2px
        @media $mPortraitMax
            grid-template-columns 1fr

    &__item
        flex 0 1 25%
        margin-right 2px
        @media $mLandscapeMax
            margin-right 0

        &:last-of-type
            margin-right 0
