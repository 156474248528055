@font-face
    font-family "FuturaLightC"
    font-weight normal
    font-style normal
    src:    local("FuturaLightC"),
            local("Futura Light C"),
            url("../fonts/Futura_Light_C/futura_light_c.woff2") format("woff2"),
            url("../fonts/Futura_Light_C/futura_light_c.woff") format("woff");
    font-display fallback

@font-face
    font-family "FuturaMediumC"
    font-weight normal
    font-style normal
    src:    local("FuturaMediumC"),
            local("Futura Medium C"),
            url("../fonts/Futura_Medium_C/futura_medium_c.woff2") format("woff2"),
            url("../fonts/Futura_Medium_C/futura_medium_c.woff") format("woff");
    font-display fallback

@font-face
    font-family "Play Rus"
    font-weight normal
    font-style normal
    src:    local("Play Rus"),
            local("PlayRus"),
            url("../fonts/Play_Rus/play_rus.woff2") format("woff2"),
            url("../fonts/Play_Rus/play_rus.woff") format("woff");
    font-display fallback

@font-face
    font-family "Rubik"
    font-weight 300
    font-style normal
    src:    local("Rubik Light"),
            local("Rubik-Light"),
            url("../fonts/Rubik/rubik_300.woff2") format("woff2"),
            url("../fonts/Rubik/rubik_300.woff") format("woff");
    font-display fallback

@font-face
    font-family "Rubik"
    font-weight 400
    font-style normal
    src:    local("Rubik"),
            local("Rubik-Regular"),
            url("../fonts/Rubik/rubik_400.woff2") format("woff2"),
            url("../fonts/Rubik/rubik_400.woff") format("woff");
    font-display fallback

@font-face
    font-family "Rubik"
    font-weight 500
    font-style normal
    src:    local("Rubik Medium"),
            local("Rubik-Medium"),
            url("../fonts/Rubik/rubik_500.woff2") format("woff2"),
            url("../fonts/Rubik/rubik_500.woff") format("woff");
    font-display fallback

@font-face
    font-family "lg"
    font-weight normal
    font-style normal
    src url("../fonts/LG/lg.eot");
    src:    url("../fonts/LG/lg.eot") format("embedded-opentype"),
            url("../fonts/LG/lg.woff") format("woff"),
            url("../fonts/LG/lg.ttf") format("truetype");
