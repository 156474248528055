.footer
    position relative
    z-index 10
    background-color #191919

    &__inner
        @extend .container
        display flex
        align-items center
        flex-wrap wrap
        padding-top 30px
        padding-bottom 23px
        @media $mLandscapeMax
            flex-direction column
            align-items stretch
            padding-bottom 33px
        @media $mPortraitMax
            padding-top 25px
            padding-bottom 25px

    &__privacy
        display flex
        align-items center
        margin-right 5.2vw
        font-size 1.6rem
        color rgba(#ffffff, .7)
        @media $tPortraitMax
            justify-content space-between
            width 100%
            margin-right 0
            margin-bottom 15px
        @media $mLandscapeMax
            margin-bottom 30px
        @media $mPortraitMax
            flex-direction column

    &__copy
        margin-right 2.08vw
        @media $mLandscapeMax
            margin-right 20px
        @media $mPortraitMax
            margin-right 0
            margin-bottom 10px

    &__legal_info
        transition color .3s ease
        @media $mPortraitMax
            color rgba(#ffffff, .8)

        &:hover
            color #ffffff

    &__meta
        margin 10px 0 10px 15px
        opacity .12
        transition opacity .3s ease
        @media $mLandscapeMax
            margin 30px 0 0
            margin-left auto
            margin-right auto

        &:hover
            opacity 1
