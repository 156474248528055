.get_card_block
    padding-bottom 100px
    @media $tPortraitMax
        padding-top 10px
        padding-bottom 0

    .card__photo
        height 70px
        transform translateY(-100px)
        margin-top 30px
        @media $tPortraitMax
            height auto
            transform none
            margin-top 30px

    &:hover
        .card__photo
            height 100px
            margin-top 0
            @media $tPortraitMax
                height auto
                margin-top 30px

    &__top
        margin-bottom 20px
        @media $mLandscapeMax
            display flex
            flex-wrap wrap
            justify-content space-between
            align-items flex-end
            margin-bottom 10px

    &__left
        @media $mLandscapeMax
            margin 10px 15px 10px 0

    &__notice
        font-size 1.6rem
        line-height 1
        color #333333
        opacity .5

    &__info
        display flex
        align-items center
        color #333333

    &__price
        margin-right 15px
        font-size 3rem
        line-height 1
        @media $mPortraitMax
            font-size 2.7rem

    &__currency
        font-family $thirdFontFamily
        font-weight 300

    &__places_left
        font-size 1.5rem
        line-height 1

    &__apply
        position absolute
        top 65px
        right -40px
        font-size 4rem
        line-height 1
        color #333333
        @media $tPortraitMax
            right 15px
            font-size 3rem
        @media $mLandscapeMax
            position static
            margin 10px 0
            font-size 2.8rem
        @media $mPortraitMax
            font-size 3.2rem

        &:hover
            .icon
                transform translateX(20px)

        .icon
            margin-left 30px
            transition transform .3s ease
            @media $mPortraitMax
                display none
