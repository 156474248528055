.filter
    border 1px solid #e2e2e2

    .filter_group
        margin-bottom 25px

    &__header
        display flex
        justify-content space-between
        align-items center
        padding 20px 19px 20px
        @media $tPortraitMax
            cursor pointer
            padding-top 19px
            padding-bottom 16px
        @media $mPortraitMax
            padding-top 14px
            padding-bottom 11px

    &__toggle_indicator
        display none
        color #8e8e8e
        transition transform .3s ease
        @media $tPortraitMax
            display block

    &__toggle_content
        border-top 1px solid #e2e2e2
        @media $tPortraitMax
            display none

    &__title
        font-size 3rem
        line-height 1
        color #333333
        opacity .9
        user-select none
        @media $tPortraitMax
            font-size 2.5rem

    &__footer
        margin-top 30px
        padding-left 20px
        padding-right 20px
        padding-bottom 2.34vw
        @media $quadHD
            padding-bottom 45px
        @media $tPortraitMax
            display flex
            justify-content space-between
            flex-wrap wrap
            padding-bottom 25px
        @media $mPortraitMax
            flex-direction column


    &__apply
        width 100%
        font-family $thirdFontFamily
        font-size 1.8rem
        line-height 1
        font-weight 400
        @media $tPortraitMax
            width auto
            min-width 220px
            flex 0 1 40%
            margin 5px 10px 5px 0
        @media $mPortraitMax
            width 100%
            flex auto
            margin 0

    &__reset
        width 100%
        margin-top 20px
        //padding 10px 15px
        font-size 1.8rem
        line-height 1
        color #333333
        border-color #cccccc
        //opacity .7
        transition border-color .3s ease

        &:hover
        &:active
            border-color #5f5f5f

        @media $tPortraitMax
            width auto
            min-width 220px
            flex 0 1 40%
            margin 5px 0
        @media $mPortraitMax
            width 100%
            flex auto
            margin-top 20px
            margin-bottom 0

        .icon
            margin-left 7px
            width 9px
            height 9px

    &--opened

        .filter__toggle_indicator
            transform rotateZ(180deg)
