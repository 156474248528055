.card
    position relative
    display block
    max-width 900px
    padding 2.34vw 2.6vw 165px
    border-radius 3px
    background-color #f7f8fb
    @media $quadHD
        padding 45px 50px 165px
    @media $tPortraitMax
        padding 25px 25px 0
    @media $mPortraitMax
        padding 20px 15px 0

    &__photo
        position absolute
        top 100%
        left 2.6vw
        transform translateY(-165px)
        height 100px
        margin-top 65px
        overflow hidden
        transition margin-top .3s ease, height .3s ease
        @media $quadHD
            left 50px
        @media $tPortraitMax
            position static
            transform none
            top unset
            left unset
            height auto
            margin-top auto
            text-align center

    &:hover
        .card__photo
            height 130px
            margin-top 35px
            @media $tPortraitMax
                height auto
                margin-top auto
