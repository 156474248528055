.order_info
    color #333333

    &__number
        font-size 4rem
        line-height 1.2
        opacity .9
        @media $desktopMax
            font-size 3.3rem
        @media $laptopMax
            font-size 3rem
        @media $tLandscapeMax
            font-size 2.8rem

    &__detailed
        margin-top 12px
        margin-bottom -5px
        font-size 1.8rem
        line-height 1.2

    &__count
    &__cost
        display inline-block
        vertical-align middle
        margin 5px 0

    &__count
        margin-right 30px
        @media $desktopMax
            margin-right 20px

    &__currency
        font-family $thirdFontFamily
        font-weight 300
