.contacts_block
    display flex
    align-items center

    &__item
        margin-right 20px

        &:last-of-type
            margin-right 0

    &__callback
        line-height 1
        color #e41563
        transition opacity .3s ease

        &:hover
            opacity .8

        &:active
            opacity .6

    &__address
        line-height 1

    &--header
        grid-column 1 / 4
        align-items flex-start
        font-size 2.2rem
        color inherit
        @media $tLandscapeMax
            grid-column 1 / 3
            font-size 2rem
        @media $tPortraitMax
            display none

        .contacts_block__socials

            .icon
                color inherit

                &--instagram
                    width 21px
                    height 21px

        .contacts_block__address_link
        .contacts_block__phone
        .socials__link
            transition opacity .3s ease

            &:hover
                opacity .6

            &:active
                opacity .3

    &--footer
        margin-right auto
        font-size 1.6rem
        color rgba(#ffffff, .7)
        @media $mLandscapeMax
            display grid
            grid-template-columns repeat(2, 1fr)
            grid-gap 15px 25px
            margin-right 0
        @media $mPortraitMax
            //display grid
            //grid-template-columns repeat(2, 1fr)
            //grid-template-rows repeat(3, 1fr)
            //grid-gap 10px 15px
            display flex
            flex-direction column
            font-size 1.9rem
            color rgba(#ffffff, .8)

        .contacts_block__phone
        .contacts_block__email
        .contacts_block__address_link
            transition color .3s ease

            &:hover
                color #ffffff

        .contacts_block__item
            line-height 1
            @media $mLandscapeMax
                margin-right 0
            @media $mPortraitMax
                margin-right 0
                margin-bottom 23px

            &:last-of-type
                @media $mPortraitMax
                    margin-bottom 0

            &:nth-child(1) // Инста
                @media $mLandscapeMax
                    justify-self flex-end
            //        padding-right 15px
            //
            //&:nth-child(2) // Адрес
            //    @media $mLandscapeMax
            //        //grid-area 1 / 1 / 2 / -1
            //        grid-column 1 / -1
            //
            &:nth-child(3) // Телефон
                @media $mLandscapeMax
                    justify-self flex-end
            //        //grid-column 2 / 1 / 3 / -1
            //        grid-column 1 / -1
            //
            //&:nth-child(4) // Почта
            //    @media $mLandscapeMax
            //        grid-area 3 / 2 / -1 / -1

            &--phone
                @media $mPortraitMax
                    margin-bottom 18px

        .socials__link
            color rgba(#ffffff, .2)
            transition color .3s ease

            &:hover
            &:active
                color #ffffff
