.textarea
    width 100%
    min-height 45px
    padding 11px 10px 8px
    //padding 0 10px
    font-size 2.4rem
    line-height 1.2
    color #192131
    border none
    border-bottom 1px solid #4b5364
    background-color transparent
    resize none
    transition opacity .3s ease

    &:hover
        opacity .5

        + .label
            opacity .5

    &:focus
        opacity 1

        + .label
            top 0
            left 5px
            font-size 1.2rem
            opacity .5
