.success
    display flex
    flex-direction column
    align-items flex-start
    @media $tPortraitMax
        align-items stretch

    &__title
        font-size 8rem
        line-height 1
        color #ffffff
        margin-bottom 2.08vw
        @media $quadHD
            margin-bottom 40px
        @media $desktopMax
            font-size 6.5rem
        @media $laptopMax
            font-size 5.5rem
            margin-bottom 30px
        @media $tLandscapeMax
            font-size 5rem
        @media $tPortraitMax
            font-size 4.5rem
        @media $mLandscapeMax
            font-size 3.6rem

    &__text
        font-size 2.4rem
        line-height 1
        color #b6b6b6

    &__buttons
        display flex
        align-items center
        margin-top 4.68vw
        @media $quadHD
            margin-top 90px
        @media $tPortraitMax
            justify-content space-between
            margin-top 50px
        @media $mPortraitMax
            flex-direction column
            align-items center

    &__accept
        margin-right 3.64vw
        @media $quadHD
            margin-right 70px
        @media $mLandscapeMax
            margin-right 15px
        @media $mPortraitMax
            margin-right 0
            margin-bottom 20px

    &__change_page
        font-size 2rem
        color #ffffff

        &:hover
            .icon
                transform translateX(20px)
                opacity 1

        .icon
            width 78px
            height 13px
            opacity 0
            transition transform .3s ease, opacity .3s ease
            @media $mLandscapeMax
                display none
