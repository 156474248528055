:root
    --header_height 310px
    @media $laptopMax
        --header_height 248px
    @media $tLandscapeMax
        --header_height 200px
    @media $tPortraitMax
        --header_height 74px

.modal_opened

    body
        height 100vh
        overflow-y hidden
        padding-right var(--scrollbar_width)

    .modal
        overflow-y scroll

    .header__desktop
        @media $tPortraitMax
            overflow-y scroll

    .header__mobile
        right var(--scrollbar_width)

    .header__bottom--fixed
        right var(--scrollbar_width)

    .header--light
        right var(--scrollbar_width)

    .product_modal
        overflow-y scroll

    .video_modal
        overflow-y scroll
