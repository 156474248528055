.header
    color #333333
    @media $tPortraitMax
        min-height 74px
        color #ffffff

    &__desktop
        @media $tPortraitMax
            display none
            position fixed
            z-index 11
            top 0
            right 0
            bottom 0
            left 0
            overflow-y hidden
            background-color #0e0e18

    &__mobile
        display none
        @media $tPortraitMax
            position fixed
            z-index 10
            top 0
            left 0
            right 0
            display flex
            justify-content space-between
            align-items center
            padding 17px 15px
            background-color #ffffff
            box-shadow 0 1px 9px 0 rgba(#000000, .1)

        .header__logo
            margin-right auto
            color #333333

        .cart
            margin-right 30px

    &__close_menu
        color #ffffff
        opacity 1
        transition opacity .3s ease

        &:hover
            opacity .7

        &:active
            opacity .4

    &__modal_controls
        display none
        @media $tPortraitMax
            display flex
            justify-content flex-end
            padding 30px

    &__wrapper
        @media $tPortraitMax
            display flex
            flex-direction column
            padding-bottom 65px

    &__top
        @media $tPortraitMax
            order 2

        .header__inner
            display grid
            grid-template-columns repeat(7, 1fr)
            align-items center
            padding-top 2.08vw
            padding-bottom 2.34vw
            @media $tLandscapeMax
                grid-template-columns repeat(6, 1fr)
            @media $tPortraitMax
                display flex

        .header__logo
            @media $tLandscapeMax
                grid-column 3 / 4
            @media $tPortraitMax
                display none

        .header__contact_us
            grid-column 5 / -1
            @media $tLandscapeMax
                grid-column 4 / -1

    &__bottom
        position relative
        border-top 1px solid rgba(#333333, .1)
        border-bottom 1px solid rgba(#333333, .1)
        @media $tPortraitMax
            order 1
            border none

        .header__inner
            display flex
            justify-content space-between
            align-items center
            @media $tPortraitMax
                flex-direction column
                align-items stretch

        .header__logo
            display none
            margin-right 3.64vw
            flex-shrink 1
            @media $quadHD
                margin-right 70px
            @media $desktopMax
                margin-right 3vw
            @media $tLandscapeMax
                margin-right 25px
            @media $tPortraitMax
                margin-right 30px

            .icon
                @media $tLandscapeMax
                    width 55px
                    height 23px

        .main_nav

            &__item
                @media $desktopMax
                    margin-right 1.6vw
                @media $tLandscapeMax
                    margin-right 15px

            &__link
                @media $tLandscapeMax
                    font-size 1.7rem

        .cart
            @media $tPortraitMax
                display none

        &--fixed
            position fixed
            z-index 10
            top 0
            left 0
            right 0
            color #333333
            background-color #ffffff
            box-shadow 0 1px 9px rgba(#000000, .1)
            transition background-color .5s ease, box-shadow .5s ease

            .header__logo
                display flex

            .header__inner
                justify-content flex-start

            .cart
                margin-left auto

    &__inner
        @extend .container

    &__logo
        justify-content center

    &__contact_us
        display flex
        justify-content flex-end
        align-items center
        @media $tPortraitMax
            flex-grow 1
            justify-content space-between
            flex-wrap wrap
        @media $mPortraitMax
            flex-direction column
            align-items stretch

        .contacts_block--header
            @media $tPortraitMax
                order 1
                display flex
                margin-top 30px
                font-size 3.5rem
                line-height 1
                color #87878c

    &__callback
        margin-right 2.34vw
        color #333333
        border-color rgba(#333333, .2)
        @media $tPortraitMax
            order 2
            margin-top 30px
            margin-right 0
            color #ffffff
            border-color #ffffff

        &:hover
            border-color #5f5f5f
            @media $tPortraitMax
                border-color #ffffff

    &--light
        position absolute
        z-index 10
        top 0
        left 0
        right 0
        color #ffffff

        .btn--default
            &:hover
                border-color #ffffff

        .header__callback
            color #ffffff
            border-color rgba(#ffffff, .2)
            @media $tPortraitMax
                border-color #ffffff

        .header__bottom
            border-top-color rgba(#ffffff, .1)
            border-bottom-color rgba(#ffffff, .1)
            @media $tPortraitMax
                border none

            &--fixed

                .header__logo
                    color #1c1c1c

                .cart

                    &__icon
                        color #333333

        .header__mobile
            .header__logo
                color #333333

        .header__logo
            color #ffffff

        .cart

            &__icon
                color #ffffff

        .main_nav__item
            &--current
                &:hover
                    border-bottom-color #ffffff
