.download

    &__item
        display flex
        flex-direction column
        align-items flex-start
        margin-bottom 30px

        &:last-of-type
            margin-bottom 0

    &__link
        display flex
        font-size 2rem
        line-height 1
        color #1c1c1c
        transition color .3s ease

        &:hover
            color #e41563

            .download__icon
                opacity .7

    &__icon
        display flex
        color #e41563
        transition opacity .3s ease

    &__info
        display flex
        flex-direction column
        margin-left 12px
        padding-top 6px

    &__meta
        font-size 1.4rem
        color #818181

    &__name
        margin-top 5px

    &__extension
        margin-left 5px

        &::before
            content ""
            display inline-block
            font inherit

    &__link[href$=".zip"] &__extension::before
        content "zip"

    &__link[href$=".zap"] &__extension::before
        content "zap"

    &__link[href$=".rar"] &__extension::before
        content "rar"

    &__link[href$=".pdf"] &__extension::before
        content "pdf"

    &__link[href$=".xls"] &__extension::before
        content "xls"

    &__link[href$=".xlsx"] &__extension::before
        content "xlsx"

    &__link[href$=".doc"] &__extension::before
        content "doc"

    &__link[href$=".docx"] &__extension::before
        content "docx"

    &__link[href$=".ppt"] &__extension::before
        content "ppt"

    &__link[href$=".pptx"] &__extension::before
        content "pptx"

    &__link[href$=".txt"] &__extension::before
        content "txt"

    &__link[href$=".jpg"] &__extension::before
        content "jpg"

    &__link[href$=".gif"] &__extension::before
        content "gif"

    &__link[href$=".png"] &__extension::before
        content "png"

    &__link[href$=".mp4"] &__extension::before
        content "mp4"

    &__link[href$=".mpeg"] &__extension::before
        content "mpeg"

    &__link[href$=".avi"] &__extension::before
        content "avi"

    &__link[href$=".mpg"] &__extension::before
        content "mpg"

    &__link[href$=".mkv"] &__extension::before
        content "mkv"

    &__link[href$=".jar"] &__extension::before
        content "jar"

    &__link[href$=".exe"] &__extension::before
        content "exe"

    &__link[href$=".dmg"] &__extension::before
        content "dmg"
