.to_top
    position fixed
    z-index 1
    top 0
    bottom 0
    left 0
    display flex
    justify-content center
    width 8vw
    padding-top 40px
    padding-bottom 40px
    visibility hidden
    transform translateX(-100%)
    transition background-color .3s ease, transform .3s ease, visibility .3s ease

    &:hover
        background-color rgba(#eeeeee, .2)

        .to_top__arrow
            opacity 1

    &__arrow_long
        width 79px
        height 13px
        color #787878
        opacity .34
        transform rotateZ(-90deg)
        transition opacity .3s ease

    &__arrow_short
        display none
        width 29px
        height 11px
        color #787878
        transform rotateZ(-90deg)


    &--simple
        z-index 10
        top auto
        bottom 40px
        left 20px
        transform translateX(calc(-100% - 20px))
        width 50px
        height 50px
        padding 0
        border-radius 50%
        background-color #eeeeee

        &:hover
            background-color #eeeeee

        .to_top__arrow_long
            display none

        .to_top__arrow_short
            display block

    &--visible
        visibility visible
        transform translateX(0)

