.checkbox
    display flex

    &__input
        display none

        &:checked + .checkbox__label
            &::before
                border-color #e41563
                background-color #e41563

            .checkbox__check
                opacity 1

    &__label
        position relative
        padding 3px 0 3px 36px
        font-size 2rem
        line-height 1
        color #333333
        opacity .9
        cursor pointer
        user-select none
        transition color .3s ease

        &::before
            content: ""
            box-sizing border-box
            position absolute
            top 0
            left 0
            width 26px
            height 26px
            border-radius 2px
            border 1px solid #e2e2e2
            transition border-color .3s ease, background-color .3s ease

        &:hover
            color #e41563

            &::before
                border-color #e41563

    &__check
        position absolute
        z-index 1
        top 8px
        left 6px
        width 15px
        height 11px
        opacity 0
        color #ffffff
        transition opacity .3s ease

    &--margin
        margin 5px 0
