.catalog
    display grid
    grid-template-columns 1fr 280px
    grid-template-areas "catalog_topline catalog_topline"\
                        "catalog_products catalog_filter"
    grid-gap 10px 0
    align-items flex-start
    @media $tPortraitMax
        display block

    .filter
        grid-area catalog_filter
        margin-left -1px
        @media $tPortraitMax
            position static
            margin-top 10px
            margin-bottom 20px
            border-left 1px solid #e2e2e2

    .products

        &__items
            &--three_cols
                @media $catalogVertical
                    grid-template-columns repeat(2, 1fr)
                @media $mw620
                    grid-template-columns 100%

    &__topline
        grid-area catalog_topline
        display flex
        align-items center
        @media $mLandscapeMax
            flex-direction column
            align-items flex-start

    &__products
        grid-area catalog_products

    &__category
        font-size 8rem
        line-height 1
        color #333333
        opacity .9
        @media $laptopMax
            font-size 7rem
        @media $tPortraitMax
            font-size 6rem
        @media $mLandscapeMax
            font-size 4rem

    &__sort
        padding-top 20px
        margin-left 5.73vw
        @media $quadHD
            margin-left 110px
        @media $tPortraitMax
            padding-top 15px
        @media $mLandscapeMax
            margin-left 0

        .select
            min-width 200px
