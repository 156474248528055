.section
    display flex
    flex-direction column

    &__title
        margin-bottom 3.38vw
        font-family $additionalFontFamily
        font-size 8rem
        color #333333
        text-align center
        @media $quadHD
            margin-bottom 65px
        @media $laptopMax
            font-size 6.5rem
        @media $tPortraitMax
            margin-bottom 25px
            font-size 5rem
        @media $mLandscapeMax
            font-size 4rem
        @media $mPortraitMax
            font-size 3rem

    &__more
        align-self center
        margin-top 3.9vw
        font-size 2rem
        line-height 1
        color #333333
        @media $tPortraitMax
            margin-top 40px
            font-size 2.6rem
        @media $mPortraitMax
            font-size 2rem

        .icon
            width 78px
            height 13px
            opacity 0
            transition transform .3s ease, opacity .3s ease
            @media $tLandscapeMax
                transform translateX(20px)
                opacity 1
            @media $mLandscapeMax
                display none

        &:hover
            .icon
                transform translateX(20px)
                opacity 1
