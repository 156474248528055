.age_check

    .text

        h1, h2, h3, h4
            margin-top 40px
            line-height 1.37
            color #ffffff

        p
            color #b6b6b6

    &__title
        font-size 5.2rem
        line-height 1
        color #ffffff

    &__buttons
        margin-top 40px
        display flex
        align-items center
        @media $mLandscapeMax
            flex-direction column

    &__confirm
        margin-right 2.6vw
        @media $quadHD
            margin-right 50px
        @media $mLandscapeMax
            margin-right 0

    &__deny
        font-size 2rem
        color #ffffff
        @media $mLandscapeMax
            margin-top 25px
