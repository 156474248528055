/* reset and normalize_ */
html
body
div
span
applet
object
iframe
h1
h2
h3
h4
h5
h6
p
blockquote
pre
a
abbr
acronym
address
big
cite
code
del
dfn
em
img
ins
kbd
q
s
samp
small
strike
strong
sub
sup
tt
var
dl
dt
dd
ol
ul
li
fieldset
form
label
legend
table
caption
tbody
tfoot
thead
tr
th
td
    margin 0
    padding 0
    border 0
    outline 0
    font-weight inherit
    font-style inherit
    font-family inherit
    font-size 100%
    vertical-align baseline

body
    line-height 1
    color #333
    margin 0

ol
ul
    list-style none

table
    border-collapse collapse
    border-spacing 0
    vertical-align top
    table-layout fixed

caption
th
td
    text-align left
    font-weight normal
    vertical-align top

a img
    border none

article
aside
canvas
details
figcaption
figure
footer
header
hgroup
menu
nav
section
summary
main
    margin 0
    padding 0
    border 0
    outline 0
    display block

audio
canvas
video
    display inline-block
    *display inline
    *zoom 1

audio:not([controls])
    display none

article
aside
details
figcaption
figure
footer
header
hgroup
main
nav
section
summary
    display block

audio
canvas
video
    display inline-block

audio:not([controls])
    display none
    height 0

[hidden]
    display none

html
    font-family sans-serif
    -webkit-text-size-adjust 100%
    -ms-text-size-adjust 100%

a:focus
a:active
a:hover
    outline 0

b
strong
    font-weight bold

dfn
    font-style italic

mark
    background #ff0
    color #000

code
kbd
pre
samp
    font-family monospace, serif
    font-size 1em

pre
    white-space pre-wrap

q
    quotes "\00ab" "\00bb"

q q
    quotes "\201e" "\201c"

small
    font-size 80%

sub
sup
    font-size 75%
    line-height 0
    position relative
    vertical-align baseline

sup
    top -.5em

sub
    bottom -.25em

img
    border none
    vertical-align top

.img-responsive
    display block
    max-width 100%
    height auto

svg:not(:root)
    overflow hidden

figure
    margin 0

fieldset
    border 0
    margin 0
    padding 0

legend
    border 0
    padding 0

button
input
select
textarea
    -webkit-box-sizing border-box
    -moz-box-sizing border-box
    box-sizing border-box
    font-family inherit
    font-size 100%
    margin 0

button
input
    line-height normal

button
select
    text-transform none

button
html input[type="button"]
input[type="reset"]
input[type="submit"]
    -webkit-appearance button
    cursor pointer

button[disabled]
html input[disabled]
    cursor default

input[type="checkbox"]
input[type="radio"]
    -webkit-box-sizing border-box
    -moz-box-sizing border-box
    box-sizing border-box
    padding 0

input[type="search"]
    -webkit-appearance textfield
    -moz-box-sizing content-box
    -webkit-box-sizing content-box
    -webkit-box-sizing content-box
    -moz-box-sizing content-box
    box-sizing content-box

input[type="search"]::-webkit-search-cancel-button
input[type="search"]::-webkit-search-decoration
    -webkit-appearance none

button::-moz-focus-inner
input::-moz-focus-inner
    border 0
    padding 0

textarea
    overflow auto
    vertical-align top

/* _reset and normalize */
