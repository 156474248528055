.label
    position absolute
    top 14px
    left 15px
    font-size 2.4rem
    line-height 1
    color #192131
    pointer-events none
    transition top .3s ease, left .3s ease, font-size .3s ease, opacity .3s ease
    @media $mLandscapeMax
        font-size 2.1rem
