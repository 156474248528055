.breadcrumbs
    display flex
    flex-wrap wrap
    align-items center
    margin-top -5px
    margin-bottom -5px

    &__item
        display flex
        align-items center
        margin-right 12px
        margin-top 5px
        margin-bottom 5px

        &::after
            content ""
            width 4px
            height 4px
            margin-left 10px
            border-radius 50%
            background-color #e2e2e2

        &:last-of-type
            margin-right 0

            &::after
                content none

    &__link
        font-size 1.5rem
        line-height 1
        color #969696
        opacity .9
        transition opacity .3s ease

        &:hover
            opacity 1

        &--current
            color #c2c2c2
            pointer-events none
