.icon
    display inline-block
    flex-shrink 0
    fill currentColor
    overflow hidden

.icon--arrow
    width 11.5px
    height 4.17px

.icon--arrow_long
    width 77.58px
    height 11.51px

.icon--arrow_short
    width 28px
    height 11px

.icon--burger
    width 26px
    height 6px

.icon--cart
    width 21px
    height 34px

.icon--check
    width 14.97px
    height 10.97px

.icon--comment
    width 18px
    height 17px

.icon--cross
    width 8.49px
    height 8.49px

.icon--cross_thin
    width 24.99px
    height 24.99px

.icon--download
    width 39.99px
    height 48px

.icon--fox
    width 646px
    height 656px

.icon--instagram
    width 21px
    height 21px

.icon--like
    width 21px
    height 20px

.icon--logo
    width 106px
    height 152.63px

.icon--logo_simple
    width 71px
    height 30px

.icon--meta
    width 44px
    height 12px

.icon--play
    width 98px
    height 98px

.icon--plus_thin
    width 65px
    height 65px

.icon--quotes
    width 17px
    height 18px

