.text
    font-size 2.4rem
    color #636363
    @media $mLandscapeMax
        font-size 2.2rem

    h1, h2, h3, h4
        color #1c1c1c
        opacity .9

    h1
        margin 3.1vw 0 1.56vw
        font-size 8rem
        line-height 1
        @media $quadHD
            margin 60px 0 30px
        @media $desktopMax
            font-size 6.5rem
        @media $laptopMax
            font-size 5rem
        @media $tLandscapeMax
            font-size 4rem
        @media $tPortraitMax
            margin 30px 0 20px
            font-size 3.5rem
        @media $mLandscapeMax
            font-size 3rem
        @media $mPortraitMax
            font-size 2.8rem

    h2
        margin 5.2vw 0 1.82vw
        font-size 5rem
        //line-height 5.5rem
        line-height 1.1
        @media $quadHD
            margin 100px 0 35px
        @media $desktopMax
            font-size 4.5rem
        @media $laptopMax
            font-size 4rem
        @media $tLandscapeMax
            font-size 3.5rem
        @media $tPortraitMax
            margin 50px 0 20px
            font-size 3rem
        @media $mLandscapeMax
            font-size 2.8rem
        @media $mPortraitMax
            font-size 2.6rem

    h3
        margin 5.2vw 0 1.56vw
        font-size 4rem
        //line-height 4.5rem
        line-height 1.1
        @media $quadHD
            margin 100px 0 30px
        @media $laptopMax
            font-size 3.5rem
        @media $tLandscapeMax
            font-size 3rem
        @media $tPortraitMax
            margin 50px 0 20px
            font-size 2.8rem
        @media $mLandscapeMax
            font-size 2.6rem
        @media $mPortraitMax
            font-size 2.4rem

    h4
        margin 5.2vw 0 2.08vw
        font-size 3rem
        //line-height 3.2rem
        line-height 1.05
        @media $quadHD
            margin 100px 0 40px
        @media $tLandscapeMax
            font-size 2.8rem
        @media $tPortraitMax
            margin 50px 0 20px
            font-size 2.6rem
        @media $mLandscapeMax
            font-size 2.4rem
        @media $mPortraitMax
            font-size 2.2rem

    p
        margin 15px 0
        font-size 2.4rem
        line-height 3.1rem
        @media $mLandscapeMax
            font-size 2.2rem
            line-height 2.8rem

    a
        color #e41563
        border-bottom 1px solid transparent
        transition border-color .3s ease

        &:hover
            border-bottom-color #f01064

    img
        display block
        margin 35px 0
        @media $mLandscapeMax
            margin 20px auto

        &.h-pull_left
            margin 0 25px 0 0
            @media $mLandscapeMax
                margin 20px auto
                float none

        &.h-pull_right
            margin 0 0 0 25px
            @media $mLandscapeMax
                margin 20px auto
                float none

        &.h-centered
            margin 40px auto
            @media $mLandscapeMax
                margin 20px auto

    blockquote
        position relative
        margin 40px 80px 40px 20px
        padding 60px 50px 30px
        border-radius 3px
        background-color #f7f8fb
        @media $tPortraitMax
            margin 30px 0
            padding 40px 40px 25px
        @media $mLandscapeMax
            padding 35px 25px 20px

        p
            margin-bottom 25px
            font-size 2.6rem
            color #333333
            @media $mLandscapeMax
                margin-bottom 20px
                font-size 2.4rem

        cite
            display flex
            align-items center
            margin-left -10px
            font-size 2rem
            line-height 1
            color #959595

            &::before
                content ""
                width 14px
                height 1px
                margin-right 10px
                background-color #959595

        .icon
            position absolute
            top 40px
            left 40px
            width 18px
            height 18px
            color #661339
            @media $tPortraitMax
                top 20px
                left 30px
            @media $mLandscapeMax
                left 20px

    ul
        margin 45px 0
        padding-left 36px
        @media $tPortraitMax
            margin 35px 0
        @media $mLandscapeMax
            margin 25px 0
            padding-left 0

        li
            margin 7px 0
            padding-left 14px

            &::before
                content ""
                display inline-block
                vertical-align top
                width 6px
                height 6px
                margin-top 12px
                margin-right 8px
                margin-left -14px
                border-radius 50%
                background-color #e41563

        ul
            margin 0
            padding-left 10px

            li
                margin 8px 0

                &::before
                    box-shadow -10px 0 0 0 #e41563

            ul

                li
                    &::before
                        margin-right 18px
                        box-shadow: -10px 0 0 0 #e41563,
                                    10px 0 0 0 #e41563

    ol
        margin 45px 0
        padding-left 50px
        counter-reset point_1
        @media $tPortraitMax
            margin 35px 0
        @media $mLandscapeMax
            margin 25px 0
            padding-left 0

        li
            margin 7px 0
            display table

            &:last-child
                margin-bottom 0

            &::before
                content counter(point_1) "."
                counter-increment point_1
                display table-cell
                vertical-align top
                padding-top 9px
                padding-right 8px
                font-family inherit
                font-size 1.6rem
                line-height 1
                color #818181

        ol
            margin 0
            padding-left 0
            counter-reset point_2

            li
                margin 8px 0

                &:last-child
                    margin-bottom 0

                &::before
                    content counter(point_1) "." counter(point_2)
                    counter-increment point_2

            ol
                counter-reset point_3

                li
                    &::before
                        content counter(point_1) "." counter(point_2) "." counter(point_3) "."
                        counter-increment point_3

    .table_wrap
        margin 35px 0
        @media $tPortraitMax
            margin 30px 0

    table
        display table
        border-collapse collapse
        border-spacing 0
        min-width 100%

        caption
            margin-bottom 15px
            font-size 1.6rem
            line-height 1.2
            color #878787

        th
            padding 18px 21px
            font-size 1.6rem
            line-height 1.2rem
            color #333333

        td
            padding 13px 21px 10px
            font-size 2rem
            line-height 1.2
            color #636363
            border-bottom 1px solid #f1f1f1

        thead

            tr
                border-bottom 1px solid #000000

        tbody

            tr
                transition background-color .3s ease

                &:hover
                    background-color #f9f9f9

                &:last-of-type
                    th
                        border-bottom none

                        & ~ td
                            border-bottom none

            th
                border-bottom 1px solid #f1f1f1
                border-right 1px solid #000000

    .embed-video
        margin 40px 0
        @media $tPortraitMax
            maargin 30px 0

    &__lead
        margin 30px 0 65px
        @media $laptopMax
            margin 30px 0 50px
        @media $tPortraitMax
            margin 25px 0 40px
        @media $mLandscapeMax
            margin 20px 0 30px

        p
            font-size 2.9rem
            line-height 3.6rem
            color #333333
            @media $mLandscapeMax
                font-size 2.4rem
                line-height 2.9rem

        &--bg
            padding 2.86vw 3.1vw 3.1vw
            background-color #6d153d
            @media $quadHD
                padding 55px 60px 60px
            @media $tPortraitMax
                padding 20px 25px

            p
                color #ffffff

.table_wrap
    position relative
    display block
    width auto
    overflow hidden
    overflow-x auto
    -webkit-overflow-scrolling touch
