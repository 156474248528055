.order
    display grid
    grid-template-columns 65.625% 1fr
    grid-template-rows max-content 1fr
    grid-template-areas "order_set order_form"
    grid-gap 70px 40px
    align-items flex-start
    @media $laptopMax
        grid-column-gap 30px
    @media $tLandscapeMax
        grid-template-columns 1fr
        grid-template-areas "order_set"\
                            "order_form"
        grid-gap 50px 0
    @media $mLandscapeMax
        grid-row-gap 35px

    &--ordered
        grid-template-areas "order_set order_info"\
                            "order_set publication_card"
        @media $tLandscapeMax
            grid-template-areas "order_info"\
                                "order_set"\
                                "publication_card"
            padding-top 32px

        .order__set
            @media $tLandscapeMax
                padding-top 0

    &__set
        grid-area order_set
        //flex 0 1 65.625%

    &__list
        border 1px solid #e2e2e2

    &__item
        border-bottom 1px solid #e2e2e2

        &:last-of-type
            border-bottom none

    &__form
        grid-area order_form
        //flex 0 1 31.25%
        @media $tLandscapeMax
            padding-top 0

    &__info
        grid-area order_info
        //margin-bottom 70px
        padding-top 25px
        @media $tLandscapeMax
            padding-top 0

    &__clear
        display flex
        align-items center
        margin-top 20px
        margin-left auto
        font-size 1.4rem
        color #333333
        opacity .4
        transition opacity .3s ease

        &:hover
            opacity 1

        .icon
            width 9px
            height 9px
            margin-left 10px
