.product_modal
    display none
    position fixed
    z-index 999
    top 0
    right 0
    bottom 0
    left 0
    min-height 100vh
    background-color rgba(#0e0e18, .9)
    overflow-y hidden

    &__wrapper
        position relative
        display flex
        justify-content center
        align-items center
        width 100%
        min-height 100%
        padding 20px 160px
        @media $tLandscapeMax
            padding-left 20px
            padding-right 20px
        @media $mLandscapeMax
            padding 0

    &__overlay
        position absolute
        top 0
        right 0
        bottom 0
        left 0

    &__controls
        position absolute
        top 0
        right 0
        bottom 0
        left 0
        @media $tLandscapeMax
            top 100%
            bottom -60px
            display flex
            justify-content center

    &__prev
    &__next
        position absolute
        top 0
        bottom 0
        display flex
        align-items center
        padding-left 40px
        padding-right 40px
        @media $tLandscapeMax
            position static
        @media $mLandscapeMax
            padding-left 0
            padding-right 0

        &:hover
            .icon
                opacity 1

    &__prev
        right 100%
        @media $tLandscapeMax
            margin-right 15px

        .icon
            transform rotateZ(180deg)

    &__next
        left 100%
        @media $tLandscapeMax
            margin-left 15px

    &__arrow
        width 78px
        height 13px
        color #ffffff
        opacity .2
        transition opacity .3s ease
        @media $tLandscapeMax
            opacity .8

    &__box
        position relative
        z-index 1
        max-width 1000px
        width 100%
        @media $laptopMax
            max-width 850px

    &__product
        position relative
        max-width 1000px
        padding 3.1vw 2.08vw 2.86vw
        background-color #ffffff
        @media $quadHD
            padding 60px 40px 55px
        @media $laptopMax
            max-width 850px
            padding-top 40px
            padding-bottom 40px
        @media $tPortraitMax
            padding 25px 20px

    &__close
        position absolute
        z-index 1
        top 15px
        right 15px
