.side_nav

    &__item
        margin-bottom 15px
        line-height 1

        &:last-of-type
            margin-bottom 0

    &__link
        font-size 2.1rem
        line-height 1
        color #333333
        transition color .3s ease
        @media $tLandscapeMax
            font-size 1.9rem

        &:hover
            color #e41563

            .icon
                transform translateX(13px)
                opacity 1

        .icon
            width 48px
            height 11px
            color #e41563
            opacity 0
            transition transform .3s ease, opacity .3s ease

        &--current
            font-size 3rem
            @media $tLandscapeMax
                font-size 2.7rem

        &--current&--lead
            margin-bottom 10px
