.selectize-input
    padding 12px 10px
    font-size 2rem
    line-height 1
    color #333333
    border-color #e2e2e2
    box-shadow none
    user-select none
    pointer-events none

    &.focus
        box-shadow none

.selectize-dropdown
    border-color #e2e2e2
    box-shadow none

    [data-selectable]
        padding 8px 10px
        color #333333
        cursor pointer

        &.option
            font-size 1.5rem

.selectize-control
    display flex
    cursor pointer

    &.single
        .selectize-input
            padding 0
            border none

            &::after
                z-index -1
                right 12px
                width 11px
                height 4px
                margin-top 0
                border none
                background-image url("../img/svg/icons/arrow.svg")
                background-repeat no-repeat
                transition transform .3s ease

            &.dropdown-active
                &::after
                    margin-top 0
                    transform rotateZ(180deg)

            .item
                //pointer-events none
                width 100%
                padding 13px 36px 13px 10px
                //white-space nowrap
                //text-overflow ellipsis
                //overflow hidden

            input
                display none!important

            &.has-options
                padding 0
                //padding-top 14px
                //padding-bottom 12px

        .selectize-dropdown
            border none
            box-shadow 0 3px 10px 0 rgba(#000000, .15)

    &.multi
        .selectize-input
            padding-right 30px

            &::before
                content ""
                position absolute
                right 12px
                top 50%
                transform translateY(-50%)
                display block
                width 11px
                height 4px
                margin-top 0
                border none
                background-image url("../img/svg/icons/arrow.svg")
                background-repeat no-repeat
                transition transform .3s ease

            &.dropdown-active
                &::before
                    left auto
                    right 12px
                    transform translateY(-50%) rotateZ(180deg)
                    height 4px
                    background-color transparent

            &.has-items
                padding 10px 30px 6px 8px

            > div
                min-height 23px
                margin 0 5px 5px 0
                padding 6px 30px 6px 12px!important
                font-family $thirdFontFamily
                font-size 1.1rem
                line-height 1
                color #ffffff
                border-radius 4px
                background-color #e41563
                cursor default
                transition background-color .3s ease

                &:hover
                    background-color #a0194c

                &.active
                    border none
                    color #ffffff
                    background-color #e41563

                    &:hover
                        background-color #a0194c

    &.plugin-remove_button
        [data-value]
            .remove
                top 7px
                right 12px
                width 9px
                height 9px
                font-size 0
                border-left none
                pointer-events auto

                &::before
                    content ""
                    position absolute
                    top 0
                    left 0
                    width 9px
                    height 9px
                    background-image url("../img/svg/icons/cross.svg")
                    background-repeat no-repeat
