.h-noselectable
    -webkit-touch-callout none
    -webkit-user-select none
    -khtml-user-select none
    -moz-user-select none
    -ms-user-select none
    user-select none

.h-cursor_default
    cursor default

.h-justify_spacer
    display inline-block
    width 100%

.h-relative
    position relative

.h-centered
    margin-left auto
    margin-right auto

.h-pull_left
    float left

.h-pull_right
    float right

.h-clear
    zoom 1
    &:after
        clear both

.h-clear:before,
.h-clear:after
    content ''
    display table

.h-clear_block
    display block
    clear both

img
    &.h-align_left
        float left
        margin 0em 2em 1em 0
    &.h-align_right
        float right
        margin 1em 0 1em 1em
    &.h-align_center
        display block
        margin 1em auto

.h-talign_left
    text-align left !important

.h-talign_center
    text-align center

.h-talign_right
    text-align right !important

.h-talign_justify
    text-align justify

.h-valign_top
    vertical-align top

.h-valign_middle
    vertical-align middle

.h-valign_bottom
    vertical-align bottom

.h-valign_baseline
    vertical-align baseline

.h-width_full
    width 100%
    max-width 100%!important

.h-width_half
    width 50%

.h-width_47
    width 47%

.h-hidden
    display none

.h-invisible
    visibility hidden

.h-visible
    visibility visible

:root
    .h-gradient
        filter none /

.h-nomargins
    margin 0

.h-nomargin_top
    margin-top 0

.h-nomargin_right
    margin-right 0

.h-nomargin_bottom
    margin-bottom 0

.h-nomargin_left
    margin-left 0

.h-nopadding
    padding 0

.h-nopadding_top
    padding-top 0

.h-nopadding_right
    padding-right 0

.h-nopadding_bottom
    padding-bottom 0

.h-nopadding_left
    padding-left 0

table.h-noborders th,
table.h-noborders td
    border none

table
    &.h-nocaption
        caption
            display none

.hidden_xs
    @media $mLandscapeMax
        display none !important

.hidden_sm
    @media $tPortrait
        display none !important

.hidden_md
    @media $tLandscape
        display none !important

.hidden_lg
    @media $desktop
        display none !important

.hidden_sm_max
    @media $tPortraitMax
        display none !important

.hidden_sm_min
    @media $tPortrait
        display none !important

.visually-hidden
    position absolute !important
    overflow hidden !important
    clip rect(0 0 0 0) !important
    height 1px !important
    width 1px !important
    margin -1px !important
    padding 0 !important
    border 0 !important
