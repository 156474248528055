.input
    width 100%
    padding 14px 10px 6px
    font-size 2.4rem
    line-height 1
    color #192131
    border none
    border-bottom 1px solid #4b5364
    background-color transparent
    transition opacity .3s ease
    @media $mLandscapeMax
        font-size 2.1rem

    &:hover
        opacity .5

        + .label
            opacity .5

    &:focus
        opacity 1

        + .label
            top 0
            left 5px
            font-size 1.2rem
            opacity .5
