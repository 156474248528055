.publication
    position relative

    > *
        &:last-child
            margin-bottom 0

    &__top
        display flex
        flex-wrap wrap
        justify-content space-between
        align-items center
        @media $tLandscapeMax
            flex-direction column
            align-items start
            margin-top 25px

    &__title
        margin 3.1vw 0 1.56vw
        font-size 8rem
        line-height 1
        color #1c1c1c
        opacity .9
        @media $quadHD
            margin 60px 0 30px
        @media $desktopMax
            font-size 6.5rem
        @media $laptopMax
            font-size 5rem
        @media $tLandscapeMax
            margin 20px 0 15px
            font-size 4rem
        @media $tPortraitMax
            font-size 3.5rem
        @media $mLandscapeMax
            font-size 3rem
        @media $mPortraitMax
            font-size 2.8rem

    &__meta
        position absolute
        top calc(3.1vw + 30px)
        left -22.77%
        display flex
        flex-wrap wrap
        align-items center
        max-width 22%
        width 100%
        padding-left 1.04vw
        font-size 1.6rem
        line-height 1
        color rgba(#333333, .7)
        @media $laptopMax
            position static
            max-width none
            width 100%
            margin-bottom 20px
            padding-left 15px
        @media $mLandscapeMax
            margin-bottom 15px

    &__date
        margin 5px 20px 5px 0
        @media $laptopMax
            margin-top 0
            margin-bottom 0

    &__tag
        margin 5px 0
        transition color .3s ease
        @media $laptopMax
            margin-top 0
            margin-bottom 0

        &:hover
            color #333333

    &__rubricator
        display none
        @media $tLandscapeMax
            display block
            min-width 140px
            margin 5px 0
