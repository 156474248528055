.contacts
    display grid
    grid-template-columns 47.5% 49.375%
    grid-gap 5.73vw 2.6vw
    align-items flex-start
    @media $quadHD
        grid-gap 110px 50px
    @media $tLandscapeMax
        grid-template-columns 100%
        grid-template-rows repeat(3, max-content)
        grid-gap 4.47vw 0
    @media $mPortraitMax
        grid-row-gap 30px

    .contacts_block
        flex-wrap wrap
        font-size 3rem
        color rgba(#333333, .9)
        @media $tLandscapeMax
            font-size 2.6rem
        @media $tPortraitMax
            font-size 2.4rem
        @media $mLandscapeMax
            font-size 2.2rem

        &__item
            margin-top 5px
            margin-bottom 5px
            margin-right 5.2vw
            @media $quadHD
                margin-right 100px
            @media $tLandscapeMax
                margin-right 2.84vw
            @media $tPortraitMax
                margin-right 30px

            &:last-of-type
                margin-right 0

        &__callback
            font-size 2rem

        &__phone
        &__email
            line-height 1
            transition opacity .3s ease

            &:hover
                opacity .6

            &:active
                opacity .3

    &__info
        grid-column 1 / -1
        @media $tLandscapeMax
            grid-row 1 / 2

    &__map
        min-height 40vh
        height 100%
        max-height 736px
        @media $tPortraitMax
            max-height 50vh
        @media $mLandscapeMax
            max-height 40vh
        background-image url("../img/map.jpg")
        background-size cover
        background-repeat no-repeat
        @media $tLandscapeMax
            grid-row 3 / -1

    &__form
        @media $tLandscapeMax
            grid-row 2 / 3
