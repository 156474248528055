.product_card
    display flex
    justify-content space-between
    padding 2.6vw 1.56vw
    color #333333
    transition box-shadow .3s ease
    @media $quadHD
        padding 50px 30px
    @media $catalogVertical
        flex-direction column
    @media $tPortraitMax
        padding 25px 20px 20px

    &:hover
        box-shadow 0 1px 9px 0 rgba(#000000, .1)

    .cart_adding__add
        margin 5px 0
        color #333333
        white-space nowrap
        border-color #eeeeee
        @media $laptopMax
            padding-left 22px
            padding-right 22px
        @media $tPortraitMax
            margin 5px 0

        &:hover
            border-color #5f5f5f

    &__photo
        flex-shrink 0
        display flex
        align-items center
        min-height 265px
        margin-right 30px
        user-select none
        @media $laptopMax
            margin-right 20px
        @media $catalogVertical
            align-self center
            margin-right 0
            margin-bottom 20px

        img
            max-width 72px
            max-height 265px

    &__info
        flex 1 1 auto
        display grid
        grid-template-rows minmax(33px, auto) 1fr minmax(35px, auto)
        padding-top 60px
        padding-bottom 35px
        //flex-direction column
        @media $catalogVertical
            padding-top 0
            padding-bottom 0
        @media $tPortraitMax
            grid-template-rows minmax(33px, auto) 1fr auto

    &__specials
        display flex
        flex-wrap wrap
        align-items flex-start

    &__special
        margin-right 5px
        margin-bottom 5px
        padding 6px 13px
        font-family $thirdFontFamily
        font-size 1.1rem
        line-height 1
        font-weight 400
        color #ffffff
        border-radius 4px
        background-color #e41563
        transition background-color .3s ease

        &:last-of-type
            margin-right 0

        &:hover
        &:active
            background-color #a0194c

    &__title
        grid-row 2 / 3
        line-height 1

    &__link
        font-size 1.8rem
        line-height 2.6rem
        transition color .3s ease

        &:hover
            color #e41563

    &__footer
        align-self flex-end
        grid-row 3 / -1
        display flex
        flex-wrap wrap
        justify-content space-between
        align-items center
        padding-right 15px
        @media $tPortraitMax
            flex-wrap wrap
            margin-top 10px

    &__prices
        line-height 1
        @media $tPortraitMax
            margin 5px 0

    &__price
        display inline-block
        margin 5px 10px 5px 0
        font-size 2rem

    &__currency
        font-family $thirdFontFamily
        font-weight 300
