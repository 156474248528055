.submenu
    display flex
    flex-wrap wrap
    align-items center
    padding 1.3vw 0
    background-color #191919
    @media $quadHD
        padding 38px 0

    &__item
        margin 8px 2.86vw 8px 0
        @media $quadHD
            margin-right 55px
        @media $laptopMax
            margin-right 25px

        &:last-of-type
            margin-right 0

    &__link
        position relative
        font-size 2.4rem
        line-height 1
        color #ffffff
        transition color .3s ease
        @media $laptopMax
            font-size 2rem

        &:hover
            color #e41563

    &__count
        position absolute
        bottom 13px
        left calc(100% - 2px)
        display inline-block
        min-width 21px
        height 21px
        padding-top 5px
        padding-left 3px
        padding-right 3px
        font-family $thirdFontFamily
        font-size 1.2rem
        font-weight 400
        color #000000
        text-align center
        border-radius 50%
        background-color #ffffff
        pointer-events none
