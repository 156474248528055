.container
    width 1630px
    max-width 100%
    margin 0 auto
    padding-left 15px
    padding-right 15px
    @media $desktopMax
        width 1370px
    @media $laptopMax
        width 1200px
