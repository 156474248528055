.gallery
    display grid
    grid-gap 2px 2px

    &__item
        position relative
        display block
        border-radius 1px
        background-color #661339
        overflow hidden

        img
            width 100%
            margin 0
            transition opacity .3s ease

        &:hover
            img
                opacity .1

            .gallery__icon
                opacity 1

    &__icon
        position absolute
        top 0
        right 0
        bottom 0
        left 0
        margin auto
        width 65px
        height 65px
        color #ffffff
        opacity 0
        transition opacity .3s ease

    &--three
        grid-template-columns repeat(3, 1fr)
        @media $mPortraitMax
            grid-template-columns 100%

    &--two
        grid-template-columns repeat(2, 1fr)
        @media $mPortraitMax
            grid-template-columns 100%

    &--three
        grid-template-columns repeat(2, 1fr)
        grid-template-areas "a b"\
                            "c c"
        @media $mPortraitMax
            grid-template-columns 100%
            grid-template-areas "a"\
                                "b"\
                                "c"

        .gallery__item

            &:nth-child(1)
                grid-area a

            &:nth-child(2)
                grid-area b

            &:nth-child(3)
                grid-area c

    &--four
        grid-template-columns repeat(4, 1fr)
        @media $mPortraitMax
            grid-template-columns 100%

        .gallery__item

            &:nth-child(1)
                grid-area 1 / 1 / 2 / 2
                @media $tPortraitMax
                    grid-area 1 / 1 / 2 / 3
                @media $mPortraitMax
                    grid-area auto

            &:nth-child(2)
                grid-area 2 / 1 / 3 / 2
                @media $tPortraitMax
                    grid-area 1 / 3 / 2 / -1
                @media $mPortraitMax
                    grid-area auto

            &:nth-child(3)
                grid-area 3 / 1 / 4 / 2
                @media $tPortraitMax
                    grid-area 2 / 1 / 3 / 3
                @media $mPortraitMax
                    grid-area auto

            &:nth-child(4)
                grid-area 1 / 2 / span 3 / -1
                @media $tPortraitMax
                    grid-area 2 / 3 / 3 / -1
                @media $mPortraitMax
                    grid-area auto

    &--five
        grid-template-columns repeat(6, 1fr)
        grid-template-rows repeat(5, 1fr)
        @media $mPortraitMax
            grid-template-columns 100%

        .gallery__item

            &:nth-child(1)
                grid-area 1 / 1 / span 2 / span 2
                @media $mPortraitMax
                    grid-area auto

            &:nth-child(2)
                grid-area 1 / 3 / span 2 / span 2
                @media $mPortraitMax
                    grid-area auto

            &:nth-child(3)
                grid-area 1 / 5 / span 2 / span 2
                @media $mPortraitMax
                    grid-area auto

            &:nth-child(4)
                grid-area 3 / 1 / -1 / span 3
                @media $mPortraitMax
                    grid-area auto

            &:nth-child(5)
                grid-area 3 / 4 / -1 / span 3
                @media $mPortraitMax
                    grid-area auto

    &--six
        grid-template-columns repeat(11, 1fr)
        grid-template-rows repeat(6, 1fr)
        @media $tPortraitMax
            grid-template-columns repeat(3, 1fr)
            grid-template-rows repeat(2, 1fr)
        @media $mPortraitMax
            grid-template-columns 100%

        .gallery__item

            &:nth-child(1)
                grid-area 1 / 1 / span 2 / span 2
                @media $tPortraitMax
                    grid-area auto
                @media $mPortraitMax
                    grid-area auto

            &:nth-child(2)
                grid-area 3 / 1 / span 2 / span 2
                @media $tPortraitMax
                    grid-area auto
                @media $mPortraitMax
                    grid-area auto

            &:nth-child(3)
                grid-area 5 / 1 / -1 / span 2
                @media $tPortraitMax
                    grid-area auto
                @media $mPortraitMax
                    grid-area auto

            &:nth-child(4)
                grid-area 1 / 3 / span 3 / span 3
                @media $tPortraitMax
                    grid-area auto
                @media $mPortraitMax
                    grid-area auto

            &:nth-child(5)
                grid-area 4 / 3 / -1 / span 3
                @media $tPortraitMax
                    grid-area auto
                @media $mPortraitMax
                    grid-area auto

            &:nth-child(6)
                grid-area 1 / 6 / -1 / -1
                @media $tPortraitMax
                    grid-area auto
                @media $mPortraitMax
                    grid-area auto

    &--base
        grid-template-columns repeat(3, 1fr)
        @media $mPortraitMax
            grid-template-columns 100%
