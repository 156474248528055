.warning
    display grid
    grid-template-columns 1fr 10fr 1fr
    padding 35px 22px 30px
    font-size 2rem
    line-height 1.3
    color #4a4a4a
    background-color #131313
    @media $mLandscapeMax
        display flex
        flex-direction column
        align-items center
        padding 25px 15px

    &__text
        text-align center
        text-transform uppercase
        @media $mLandscapeMax
            margin-top 20px
