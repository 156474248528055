.logo
    display flex
    justify-self  center

    .icon--logo
        width 5.52vw
        min-width 60px
        height 7.97vw
        min-height 87px
        color inherit
        @media $quadHD
            width 106px
            height 153px

    .icon--logo_simple
        width 71px
        height 30px
        color inherit
