.cart_adding
    position relative

    &__close
        position absolute
        top 10px
        right 10px
        color #000000
        opacity .2
        transition opacity .3s ease

        &:hover
            opacity 1

    &__cross
        width 12px
        height 12px

    &__popover
        position absolute
        bottom calc(100% + 20px)
        right -21px
        transform translateY(-20px)
        visibility hidden
        padding 10px 20px 15px
        border-radius 5px
        border 1px solid #eeeeee
        background-color #ffffff
        box-shadow 0 3px 13px 0 rgba(#000000, .08)
        opacity 0
        pointer-events none
        transition opacity .3s ease, transform .3s ease, visibility .3s ease

        &::before
            content ""
            box-sizing border-box
            position absolute
            bottom -10px
            right 21px
            transform rotateZ(45deg)
            width 20px
            height 20px
            border 1px solid #eeeeee
            background-color #ffffff
            box-shadow 0 3px 13px 0 rgba(#000000, .08)

        &::after
            content ""
            position absolute
            bottom 0
            left 0
            right 0
            height 10px
            background-color #ffffff

        &--show
            visibility visible
            transform translateY(0)
            opacity 1
            pointer-events auto

    &__tip
        font-size 1.6rem
        line-height 1
        color #333333

    &__counter
        margin-top 10px

    &__controls
        display flex
        margin-top 20px

    &__accept
    &__to_cart
        margin-left auto
        margin-right auto
        padding-top 10px
        padding-bottom 10px
        font-size 1.6rem

    &__to_cart
        padding-left 20px
        padding-right 20px
        white-space nowrap

    &__step_one
    &__step_two
        display none
        background-color #ffffff

        &--show
            display block
