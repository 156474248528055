.counter
    display flex
    align-items center

    &__btn
        align-items center
        justify-content center
        width 50px
        height 50px
        font-family $thirdFontFamily
        font-weight 400
        font-size 18px
        line-height 1
        color #333333
        border-radius 50%
        border 1px solid #eeeeee
        background-color #ffffff
        transition border-color .3s ease

        &:hover
        &:active
            border-color #5f5f5f

    &__input
        width 40px
        height 30px
        padding-left 5px
        padding-right 5px
        font-family $thirdFontFamily
        font-weight 400
        font-size 1.8rem
        line-height 1
        color #333333
        text-align center
        border none
