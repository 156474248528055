.news
    @media $mPortraitMax
        display flex
        flex-direction column
        align-items flex-start

    &__photo
        position relative
        display block
        background-color #661339

        img
            width 100%
            transition opacity .3s ease

        .icon
            position absolute
            top 0
            right 0
            bottom 0
            left 0
            margin auto
            width 78px
            height 13px
            color #ffffff
            opacity 0
            transition opacity .3s ease

        &:hover

            img
                opacity .1

            .icon
                opacity 1

    &__content
        padding 15px 20px 0
        @media $desktopMax
            padding-left 15px
            padding-right 15px

    &__meta
        margin-bottom 23px
        font-size 1.6rem
        line-height 1
        color rgba(#333333, .7)
        @media $laptopMax
            margin-bottom 15px
        @media $mPortraitMax
            margin-bottom 10px

    &__date
        margin-right 20px

    &__tag
        transition color .3s ease

        &:hover
            color rgba(#b7b7b7, .7)

    &__title
        line-height 1

    &__link
        font-size 3rem
        line-height 3.2rem
        color #333333
        transition opacity .3s ease
        @media $desktopMax
            font-size 2.8rem
            line-height 3rem
        @media $laptopMax
            font-size 2.6rem
            line-height 2.9rem
        @media $tLandscapeMax
            font-size 2.5rem
            line-height 2.8rem
        @media $mLandscapeMax
            font-size 2.3rem
            line-height 2.6rem
        @media $mPortraitMax
            font-size 2.1rem
            line-height 2.4rem

        &:hover
            opacity .3
