.order_card
    display flex
    justify-content space-between
    align-items center
    padding 1.56vw 1.7vw
    @media $quadHD
        padding 30px 33px
    @media $tPortraitMax
        flex-wrap wrap
        padding 20px 15px
    @media $mLandscapeMax
        position relative
    @media $mPortraitMax
        flex-direction column
        justify-content flex-start
        align-items stretch

    .counter

        &__btn
            @media $laptopMax
                width 40px
                height 40px

    &__main
        flex 0 1 385px
        display flex
        align-items center
        margin-right 1vw
        @media $quadHD
            margin-right 20px
        @media $laptopMax
            flex-basis 305px
        @media $tPortraitMax
            flex 1 0 100%
            margin-right 0
            margin-bottom 30px
        @media $mLandscapeMax
            padding-right 50px
        @media $mPortraitMax
            padding-right 0

    &__photo
        margin-left 14px
        margin-right 14px
        @media $laptopMax
            margin-left 0
            margin-right 0
        @media $tPortraitMax
            margin-left 10px
            margin-right 10px
        @media $mPortraitMax
            margin-left 5px
            margin-right 5px

        img
            max-width 47px

    &__info
        margin-left 2.08vw
        @media $quadHD
            margin-left 40px
        @media $laptopMax
            margin-left 15px
        @media $tPortraitMax
            margin-left 25px
        @media $mPortraitMax
            margin-left 15px

    &__title
        font-size 1.8rem
        line-height 2.6rem
        color #333333

    &__link
        transition color .3s ease

        &:hover
            color #e41563

    &__description
        margin-top 7px
        font-size 1.6rem
        line-height 1.2
        color #a5a5a5

    &__cost
        flex 0 1 120px
        display flex
        flex-direction column
        margin-right 1vw
        line-height 1
        @media $quadHD
            margin-right 20px
        @media $tPortraitMax
            margin 5px 15px 5px 0
        @media $mPortraitMax
            flex-basis auto
            margin 0

        &:last-child
            margin-right 0

        &_name
            margin-bottom 7px
            font-size 1.6rem
            line-height inherit
            color #a5a5a5
            @media $mPortraitMax
                font-size 2.4rem

        &_value
            font-size 2rem
            line-height inherit
            color #333333
            @media $mPortraitMax
                font-size 2.8rem

    &__currency
        font-family $thirdFontFamily
        font-weight 300

    &__counter
        flex 0 1 140px
        margin-right 1vw
        @media $quadHD
            margin-right 20px
        @media $tPortraitMax
            margin 5px 15px 5px 0
        @media $mPortraitMax
            flex-basis auto
            margin-right 0
            margin-top 20px
            margin-bottom 20px

        &_name
            margin-bottom 7px
            font-size 1.6rem
            line-height inherit
            color #a5a5a5
            @media $mPortraitMax
                font-size 2.4rem

        &_value
            font-size 2rem
            line-height inherit
            color #333333
            @media $mPortraitMax
                font-size 2.8rem

        &--final
            display flex
            flex-direction column
            align-items center
            line-height 1
            @media $tPortraitMax
                flex-basis 80px
            @media $mPortraitMax
                flex-basis auto
                align-items stretch

    &__remove_col
        flex 0 1 50px
        @media $tPortraitMax
            margin 5px 0
        @media $mLandscapeMax
            position absolute
            top 20px
            right 15px
            margin 0
        @media $mPortraitMax
            top auto
            bottom 20px

    &__remove
        width 50px
        height 50px
        font-family $thirdFontFamily
        font-size 1.8rem
        line-height 1
        color rgba(#333333, .3)
        border-radius 50%
        border 1px solid #eeeeee
        transform rotateZ(45deg)
        transition color .3s ease, border-color .3s ease
        @media $laptopMax
            width 40px
            height 40px

        &:hover
        &:active
            color #333333
            border-color #5f5f5f
