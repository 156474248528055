.form_wrapper

    .text
        margin-top 4.69vw
        @media $quadHD
            margin-top 90px
        @media $tPortraitMax
            margin-top 50px

        p
            font-size 2rem
            line-height 2.8rem
            color #959595

        &:last-of-type
            margin-bottom 0

    &__info
        margin-top 11px
        margin-bottom 50px
        font-size 1.8rem
        color #333333
        opacity .9
        @media $mLandscapeMax
            margin-bottom 30px

        &_item
            display inline-block
            vertical-align middle
            margin 5px 30px 5px 0
            @media $desktopMax
                margin-right 20px

            &:last-child
                margin-right 0

    &__title
        margin-bottom 30px
        font-size 5rem
        line-height 5.5rem
        color #1c1c1c
        opacity .9
        @media $laptopMax
            font-size 4.5rem
            line-height 5rem
        @media $tLandscapeMax
            font-size 4.1rem
            line-height 4.6rem
        @media $tPortraitMax
            font-size 3.7rem
            line-height 4.1rem
        @media $mLandscapeMax
            margin-bottom 10px
            font-size 3.3rem
            line-height 3.7rem

        &--small
            font-size 4rem
            line-height 4.4rem
            @media $laptopMax
                font-size 3.6rem
                line-height 4rem
            @media $tLandscapeMax
                font-size 3.4rem
                line-height 3.8rem
            @media $tPortraitMax
                font-size 3.2rem
                line-height 3.6rem
            @media $mLandscapeMax
                font-size 3rem
                line-height 3.4rem

        &--big
            font-size 8rem
            line-height 1
            @media $desktopMax
                font-size 6.5rem
            @media $laptopMax
                font-size 5.5rem
            @media $tLandscapeMax
                font-size 5rem
            @media $tPortraitMax
                font-size 4.5rem
            @media $mLandscapeMax
                font-size 3.6rem

    &--light

        .input
        .textarea
            color #ffffff
            border-bottom-color #4b5364

        .label
            color #ffffff

        .form_wrapper__title
            margin-bottom 55px
            color #ffffff
            @media $mLandscapeMax
                margin-bottom 35px

    &--sending
        position relative

        &::before
            content ""
            position absolute
            top 0
            right 0
            bottom 0
            left 0
            margin auto
            width 50px
            height 50px
            border-radius 50%
            background-color #e41563

        &::after
            content ""
            position absolute
            top 0
            right 0
            bottom 0
            left 0
            margin auto
            width 18px
            height 18px
            border-radius 50%
            border 2px solid #ffffff
            border-right-color transparent
            animation spin 1.5s linear infinite

        .form_wrapper__inner
            visibility hidden

    &--franchise
        max-width 500px
        margin-top 2.6vw
        @media $quadHD
            margin-top 50px
        @media $tPortraitMax
            margin-top 30px
