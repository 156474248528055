.filter_group
    display flex
    flex-direction column
    padding 0 20px

    &__title
        margin-bottom 7px
        font-size 1.6rem
        line-height 1
        color #333333
        opacity .9
        cursor pointer
        user-select none

    &__currency
        font-family $thirdFontFamily
        font-weight 300

    &__range_picker
        display flex

        .filter_group__input
            width calc(50% - 10px)
            flex 1 1 auto

            &:first-of-type
                margin-right 20px

    &__input
        height 46px
        padding 0 10px
        font-size 1.6rem
        line-height 1
        border-radius 3px
        border 1px solid #e2e2e2

    &--separated
        border-bottom 1px solid #e2e2e2

    &--checkboxes
        padding-top 20px
        padding-bottom 20px
