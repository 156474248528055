html
    min-height 100vh
    -moz-osx-font-smoothing grayscale
    -webkit-font-smoothing antialiased
    text-rendering optimizeLegibility !important
    font-size $baseFontSize
    background-color #f3f6f9

body
    display flex
    flex-direction column
    min-height 100vh
    font-family $baseFontFamily
    line-height $baseLineHeight
    font-weight $baseFontWeight
    font-size 2.4rem
    color #636363
    overflow-y scroll
    overflow-x hidden
    background-color #ffffff

.layout
    @extend .container

    &--main
        padding-top 6.25vw
        @media $quadHD
            padding-top 120px
        @media $tPortraitMax
            padding-top 60px

        .section
            margin-bottom 7.55vw
            @media $quadHD
                margin-bottom 145px
            @media $tPortraitMax
                margin-bottom 70px

        .news_tiles
            @media $desktopMax
                grid-template-columns repeat(4, 1fr)
            @media $tLandscapeMax
                grid-template-columns repeat(3, 1fr)
            @media $mLandscapeMax
                grid-template-columns repeat(2, 1fr)
                grid-row-gap 40px
            @media $mPortraitMax
                grid-template-columns 1fr

    // Каталоги / разводящая каталогов
    &--one_col_v1
        padding-bottom 4.68vw
        @media $quadHD
            padding-bottom 90px
        @media $tPortraitMax
            padding-bottom 50px

        .content
            margin-top 3.1vw
            @media $quadHD
                margin-top 60px
            @media $tPortraitMax
                margin-top 30px

            &__top
                margin-bottom 4.68vw
                @media $quadHD
                    margin-bottom 90px
                @media $tPortraitMax
                    margin-bottom 40px

    // Каталог
    &--one_col_v2
        grid-area catalog_layout
        padding-bottom 20px

        .content
            margin-top 3.1vw
            @media $quadHD
                margin-top 60px
            @media $tPortraitMax
                margin-top 30px

        .pagination
            margin-top 2.6vw
            margin-bottom 2.6vw
            @media $quadHD
                margin-top 50px
                margin-bottom 50px
            @media $tPortraitMax
                margin-top 30px
                margin-bottom 30px

        .card
            margin-top 4.16vw
            margin-bottom 3.1vw
            @media $quadHD
                margin-top 80px
                margin-bottom 60px

    // Сына-корзина и страница заказа
    &--one_col_v3
        padding-bottom 5.2vw
        @media $quadHD
            padding-bottom 100px
        @media $tPortraitMax
            padding-bottom 50px

        .topline
            margin-bottom 3.1vw
            @media $quadHD
                margin-bottom 60px
            @media $tPortraitMax
                margin-bottom 30px

        .content

            &__top
                display block
                max-width 900px
                margin-bottom 50px
                @media $mLandscapeMax
                    margin-bottom 40px

        .contacts
            margin-top 4.16vw
            @media $quadHD
                margin-top 80px
            @media $tPortraitMax
                margin-top 40px
            @media $mLandscapeMax
                margin-top 30px

    // Франшиза
    &--one_col_v4
        padding-bottom 5.2vw
        @media $quadHD
            padding-bottom 100px
        @media $tPortraitMax
            padding-bottom 50px

        .topline
            max-width 1105px
            margin-bottom 3.1vw
            padding-left 12.81%
            @media $quadHD
                margin-bottom 60px
            @media $laptopMax
                padding-right 12.81%
            @media $tLandscapeMax
                padding-left 10vw
                padding-right 10vw
            @media $tPortraitMax
                margin-bottom 30px
            @media $mLandscapeMax
                padding-left 15px
                padding-right 15px

        .content
            max-width 1105px
            padding-left 12.81%
            @media $laptopMax
                padding-right 12.81%
            @media $tLandscapeMax
                padding-left 10vw
                padding-right 10vw
            @media $mLandscapeMax
                padding-left 15px
                padding-right 15px

        .form_wrapper
            max-width 500px
            margin-top 2.6vw
            @media $quadHD
                margin-top 50px
            @media $tPortraitMax
                margin-top 30px

    // Страница товара
    &--one_col_v5
        padding-bottom 35px

        .content
            margin-top 4.16vw
            @media $quadHD
                margin-top 80px
            @media $tPortraitMax
                margin-top 40px

        .products
            margin-top 3.38vw
            margin-bottom 3.38vw
            @media $quadHD
                margin-top 65px
                margin-bottom 65px
            @media $tLandscapeMax
                margin-top 50px
                margin-bottom 50px
            @media $mLandscapeMax
                margin-bottom 40px

    // Услуги
    &--one_col_v6
        padding-bottom 5.2vw
        @media $quadHD
            padding-bottom 100px
        @media $tPortraitMax
            padding-bottom 50px

        .content
            margin-top 3.125vw
            @media $quadHD
                margin-top 60px
            @media $tPortraitMax
                margin-top 30px

            &__top
                margin-top 3.125vw
                @media $quadHD
                    margin-top 60px
                @media $tPortraitMax
                    margin-bottom 40px

    // Под раскладкой страницы Публикации
    &--one_col_v7
        padding-top 20px
        padding-bottom 5.73vw
        @media $quadHD
            padding-bottom 110px
        @media $tPortraitMax
            padding-bottom 60px

        .content
            padding-left 12.51%
            padding-right 10.41%
            @media $laptopMax
                padding-left 0
                padding-right 0

        .card
            margin 30px 0

        .products
            margin-bottom 4.1vw
            @media $quadHD
                margin-bottom 80px
            @media $tPortraitMax
                margin-bottom 45px

            &__items
                grid-template-columns repeat(3, 1fr)
                @media $tPortraitMax
                    grid-template-columns repeat(2, 1fr)
                @media $mw620
                    grid-template-columns 100%

    // Текстовая
    &--two_cols_v1
        display grid
        grid-template-columns calc(100% - 320px) 320px
        grid-template-areas "topline topline"\
                            "content aside"\
                            "catalog_layout catalog_layout"
        padding-bottom 3.64vw
        @media $quadHD
            padding-bottom 70px
        @media $tLandscapeMax
            grid-template-columns 100%
            grid-template-areas "topline"\
                                "content"\
                                "catalog_layout"
        @media $tPortraitMax
            padding-bottom 40px

        .topline
            padding-left 12.81%
            @media $laptopMax
                padding-left 0

        .aside
            margin-top 4.4vw
            @media $quadHD
                margin-top 85px
            @media $laptopMax
                margin-top 60px
            @media $tLandscapeMax
                display none

        .content
            padding-left 16%
            padding-right 13.9%
            @media $laptopMax
                padding-left 0
                padding-right 0

            &:only-child
                padding-top 3.9vw
                @media $quadHD
                    padding-top 75px
                @media $tPortraitMax
                    padding-top 40px

        .slider
            margin 35px 0
            @media $tPortraitMax
                margin 30px 0

        .gallery
            margin 40px 0
            @media $tPortraitMax
                margin 30px 0

        .special
            margin 6.25vw 0
            @media $quadHD
                margin 120px 0
            @media $mLandscapeMax
                margin 40px 0

        .video
            margin 40px 0
            @media $tPortraitMax
                margin 30px 0

        .download
            margin-top 5.2vw
            @media $quadHD
                margin-top 100px
            @media $tPortraitMax
                margin-top 50px

        .card
            margin 30px 0

        .layout--one_col_v2
            padding-bottom 10px
            padding-left 0
            padding-right 0

            .content
                padding-left 12.81%
                padding-right 12.81%

                &:only-child
                    padding-top 0

    // Новости и события
    &--two_cols_v2
        display grid
        grid-template-columns 1fr 320px
        grid-template-areas "topline topline"\
                            "content aside"\
                            "instagram_tiles instagram_tiles"
        grid-gap 0 4.42vw
        padding-bottom 5.2vw
        @media $quadHD
            grid-gap 0 85px
            padding-bottom 100px
        @media $desktopMax
            grid-template-columns 1fr 240px
            grid-column-gap 30px
        @media $tPortraitMax
            grid-template-columns 1fr
            grid-template-areas "topline"\
                                "content"\
                                "instagram_tiles"
            grid-gap 0
            padding-bottom 50px

        .topline
            margin-bottom 3.1vw
            @media $quadHD
                margin-bottom 60px
            @media $tPortraitMax
                margin-bottom 30px

        .aside
            padding-left 0

        .news_tiles
            grid-row-gap 3.1vw
            @media $quadHD
                grid-row-gap 60px
            @media $tLandscapeMax
                grid-row-gap 40px

        .news

            &__content
                margin-bottom -.7rem
                padding-top 12px

            &__meta
                margin-bottom 11px

            &__link
                font-size 2.3rem
                line-height 2.8rem
                @media $laptopMax
                    font-size 2.1rem
                    line-height 2.6rem

        .event

            &__content
                @media $tLandscapeMax
                    margin-bottom -.7rem
                    padding-top 12px

            &__meta
                @media $tLandscapeMax
                    margin-bottom 11px

            &__link
                @media $tLandscapeMax
                    font-size 2.1rem
                    line-height 2.6rem

        .pagination
            margin-top 3.1vw
            @media $quadHD
                margin-top 60px
            @media $tPortraitMax
                margin-top 40px

        .instagram_tiles
            margin-top 4.68vw
            @media $quadHD
                margin-top 90px
            @media $tPortraitMax
                margin-top 45px

    // События
    &--two_cols_v3
        display grid
        grid-template-columns 1fr 320px
        grid-template-areas "topline topline"\
                            "content aside"
        grid-gap 0 85px
        padding-bottom 5.2vw
        @media $quadHD
            padding-bottom 100px
        @media $desktopMax
            grid-template-columns 1fr 240px
            grid-column-gap 30px
        @media $tPortraitMax
            grid-template-columns 1fr
            grid-template-areas "topline"\
                                "content"
            padding-bottom 50px

        .topline
            margin-bottom 3.1vw
            @media $quadHD
                margin-bottom 60px
            @media $tPortraitMax
                margin-bottom 30px

        .news_tiles
            grid-row-gap 2.6vw
            @media $quadHD
                grid-row-gap 50px
            @media $tLandscapeMax
                grid-row-gap 40px

        .pagination
            margin-top 2.6vw
            @media $quadHD
                margin-top 50px
            @media $tPortraitMax
                margin-top 30px

    // Новости
    &--two_cols_v4
        display grid
        grid-template-columns 1fr 320px
        grid-template-areas "topline topline"\
                            "content aside"
        grid-gap 0 85px
        padding-bottom 5.2vw
        @media $quadHD
            padding-bottom 100px
        @media $desktopMax
            grid-template-columns 1fr 240px
            grid-column-gap 30px
        @media $tPortraitMax
            grid-template-columns 1fr
            grid-template-areas "topline"\
                                "content"
            padding-bottom 50px

        .topline
            margin-bottom 3.1vw
            @media $quadHD
                margin-bottom 60px
            @media $tPortraitMax
                margin-bottom 30px

        .news_tiles
            grid-template-columns repeat(3, 1fr)
            grid-row-gap 2.6vw
            @media $quadHD
                grid-row-gap 50px
            @media $tPortraitMax
                grid-row-gap 40px
            @media (max-width 840px)
                grid-template-columns repeat(2, 1fr)
            @media $mPortraitMax
                grid-template-columns 1fr

        .pagination
            margin-top 2.6vw
            @media $quadHD
                margin-top 50px
            @media $tPortraitMax
                margin-top 30px
