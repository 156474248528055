.content
    grid-area content

    &__top
        display flex
        flex-wrap wrap
        justify-content space-between
        align-items center
        margin-bottom 40px

    &__title
        margin 5px 15px 5px 0
        font-size 8rem
        line-height 1
        color #333333
        opacity .9
        @media $desktopMax
            font-size 6.5rem
        @media $laptopMax
            font-size 5rem
        @media $tLandscapeMax
            font-size 4rem
        @media $tPortraitMax
            font-size 3.5rem
        @media $mLandscapeMax
            font-size 3rem
        @media $mPortraitMax
            font-size 2.8rem

    &__rubricator
        display none
        @media $tPortraitMax
            display block
            min-width 140px
            margin 5px 0

    &__intro
        margin-top 20px
