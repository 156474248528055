.aside
    grid-area aside
    margin-top 30px
    padding-left 20px
    @media $tPortraitMax
        display none

    &__inner
        position sticky
        top calc(var(--sticky_aside_offset) + 30px)

