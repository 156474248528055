.instagram_tile
    position relative
    display block
    overflow hidden

    &:hover

        .instagram_tile__info
            top 50%
            transform translateY(-50%)
            opacity 1

        .instagram_tile__photo
            img
                opacity .3

    &__photo
        background-color #120c07

        img
            display block
            transition opacity .3s ease

    &__info
        position absolute
        top 100%
        left 0
        right 0
        display flex
        justify-content center
        align-items center
        font-family $thirdFontFamily
        font-size 1.8rem
        line-height 1
        font-weight 500
        color #ffffff
        text-align center
        opacity 0
        transition opacity .25s ease, top .25s ease

    &__likes
        display flex
        align-items center
        margin-right 20px

        .icon
            margin-right 7px
            color #ffffff

    &__comments
        display flex
        align-items center

        .icon
            margin-right 9px
            color #ffffff
