.special
    display flex
    align-items center
    @media $mLandscapeMax
        flex-direction column
        align-items center

    &__photo
        flex 0 1 46.6%
        margin-right 50px
        @media $mLandscapeMax
            flex-basis auto
            margin-right 0
            margin-bottom 30px

    &__text
        flex 0 1 47.7%
        @media $mLandscapeMax
            flex-basis auto

        h1, h2, h3, h4
            &:first-child
                margin-top 0

    &--reverse
        flex-direction row-reverse
        @media $mLandscapeMax
            flex-direction column-reverse

        .special__photo
            margin-right 0
            margin-left 50px
            @media $mLandscapeMax
                flex-basis auto
                margin-left 0
                margin-top 30px
