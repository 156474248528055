.products

    &__header
        display flex
        justify-content space-between
        align-items center
        margin-bottom 40px
        @media $mLandscapeMax
            flex-direction column
            justify-content flex-start
            align-items flex-start
            margin-bottom 25px

    &__title
        font-size 4rem
        line-height 4.5rem
        color #1c1c1c
        opacity .9
        @media $tLandscapeMax
            font-size 3.5rem
            line-height 3.9rem
        @media $tPortraitMax
            font-size 3.2rem
            line-height 3.7rem
        @media $mLandscapeMax
            font-size 2.8rem
            line-height 3.2rem
        @media $mPortraitMax
            font-size 2.6rem
            line-height 2.9rem

    &__more
        margin-right 20px
        font-size 2rem
        line-height 1
        color #333333
        @media $mLandscapeMax
            margin-top 15px
            margin-right 0
        @media $mw620
            margin-top 20px
            display none

        &:hover
            .icon
                transform translateX(20px)
                opacity 1

        .icon
            width 78px
            height 13px
            opacity 0
            transition transform .3s ease, opacity .3s ease
            @media $tPortraitMax
                display none

        &--mobile
            display none
            @media $mw620
                display block

    &__items
        border-top 1px solid #e2e2e2
        border-left 1px solid #e2e2e2

        .product_card
            border-right 1px solid #e2e2e2
            border-bottom 1px solid #e2e2e2

        &--three_cols
            display grid
            grid-template-columns repeat(3, 1fr)
            @media $laptopMax
                grid-template-columns repeat(2, 1fr)
            @media $mw620
                grid-template-columns 100%

        &--four_cols
            display grid
            grid-template-columns repeat(4, 1fr)
            @media $desktopMax
                grid-template-columns repeat(3, 1fr)
            @media $tPortraitMax
                grid-template-columns repeat(2, 1fr)
            @media $mw620
                grid-template-columns 100%

    &__footer
        display flex
        flex-direction column
        align-items center
